import { createStore, applyMiddleware, StoreEnhancer } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import reducers from "../reducers";
import Thunk from "redux-thunk";
import PromiseMiddleware from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import { authentication } from "../middlewares/authentication";

export const history = createBrowserHistory();
const router = routerMiddleware(history);
const composeEnhancers = composeWithDevTools({});

const middleware: StoreEnhancer<any> = composeEnhancers(applyMiddleware(PromiseMiddleware, Thunk, router, authentication));

export default createStore(reducers(history), middleware);
