import React, { FunctionComponent } from "react";
import { Empty, Button } from "antd";
import { imageUrl } from "app/utils/image";
import { reload } from "app/utils/common";
import styles from "./style.module.scss";

interface IProps {
  message: string;
  refresh?: boolean;
}

const ErrorPlaceholder: FunctionComponent<IProps> = ({ message, refresh = true }) => {
  return (
    <Empty className={styles.container} image={imageUrl("common/error-placeholder.svg")} imageStyle={{ height: 60 }} description={<span>{message}</span>}>
      {refresh && (
        <Button
          onClick={() => {
            reload(true);
          }}
          type="primary">
          Refresh
        </Button>
      )}
    </Empty>
  );
};

export default ErrorPlaceholder;
