import React, { FunctionComponent } from "react";
import MainLayout from "app/layouts/MainLayout";
import SessionCreationForm from "app/container/Session/Form";

const AdminPage: FunctionComponent<any> = ({ edit = false }) => {
  return (
    <MainLayout title={edit ? "Edit Session" : "Create New Session"} subtitle={edit ? "Edit the session" : "Create a new session"}>
      <SessionCreationForm edit={edit} />
    </MainLayout>
  );
};

export default AdminPage;
