import React, { useEffect } from "react";
import { Select, Form } from "antd";
import _isEmptyObj from "lodash/isEmpty";
import { FormComponentProps } from "antd/lib/form";
const { Option } = Select;

type TItems = {
  [key: string]: any;
};

interface IProps extends FormComponentProps {
  width?: string | number;
  items?: TItems | any[];
  initialValue?: any;
  placeholder?: string;
  updateSelectChange?: any;
  label?: string;
  layout?: string;
  isRequired?: boolean;
  disable?: boolean;
  handleChange?: any;
}

const SelectItem: React.FunctionComponent<IProps> = ({
  width = 200,
  items,
  initialValue = null,
  placeholder,
  updateSelectChange,
  label,
  layout = "vertical",
  isRequired = false,
  form,
  disable = false,
  handleChange
}) => {
  const { getFieldValue, getFieldDecorator } = form;
  //functions
  const getOptions = () => {
    if (items && Array.isArray(items)) {
      return items?.map(item => <Option value={item}>{item}</Option>);
    } else if (!_isEmptyObj(items)) {
      return Object.entries(items)?.map(([key, value]) => <Option value={key}>{value}</Option>);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (initialValue) {
      form.setFieldsValue({ selectedItem: initialValue });
    } else {
      form.setFieldsValue({ selectedItem: undefined });
    }
  }, [initialValue]);

  useEffect(() => {
    const selectedItem = getFieldValue("selectedItem");
    updateSelectChange(selectedItem);
  }, [getFieldValue("selectedItem")]);

  //jsx and layout
  const formLayout =
    layout === "horizontal"
      ? {
          labelCol: { span: 2 }
        }
      : null;

  return (
    <Form.Item label={label} {...formLayout}>
      {getFieldDecorator("selectedItem", {
        rules: [
          {
            required: isRequired,
            message: "This field is Required"
          }
        ]
      })(
        <Select disabled={disable} style={{ width: width }} placeholder={placeholder} onChange={handleChange}>
          {getOptions()}
        </Select>
      )}
    </Form.Item>
  );
};

export default Form.create<IProps>({ name: "selectItem" })(SelectItem);
