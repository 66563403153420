import { accessLevels } from "../accessLevel/accessLevel";

const { OWNER, ADMIN, MANAGER, AUTHOR, LEADUP } = accessLevels;
const personalizedUrl = "/sessions/personalised";
const commonSessionUrl = "/sessions/common";
const paths = {
  home: {
    path: "/"
  },
  dashboard: {
    path: "/dashboard",
    accessLevel: LEADUP
  },
  login: {
    path: "/login"
  },

  // sessions
  allSessions: {
    path: "/sessions/all",
    accessLevel: AUTHOR
  },
  pendingSessions: {
    path: "/sessions/pending",
    accessLevel: AUTHOR
  },
  upcomingSessions: {
    path: "/sessions/upcoming",
    accessLevel: AUTHOR
  },
  scheduledSessions: {
    path: "/sessions/scheduled",
    accessLevel: AUTHOR
  },
  feedbackPending: {
    path: "/sessions/feedback-pending",
    accessLevel: AUTHOR
  },
  completedSessions: {
    path: "/sessions/completed",
    accessLevel: AUTHOR
  },
  ongoingSessions: {
    path: "/sessions/ongoing",
    accessLevel: AUTHOR
  },
  elapsedSessions: {
    path: "/sessions/elapsed",
    accessLevel: AUTHOR
  },
  rescheduleRequestsSessions: {
    path: "/sessions/reschedule-requests",
    accessLevel: AUTHOR
  },

  // webinars
  webinars: {
    path: "/webinars",
    accessLevel: AUTHOR
  },
  webinarDetails: {
    path: "/webinars/:id",
    accessLevel: AUTHOR
  },

  // coach
  coachInvite: {
    path: "/coach-invite",
    accessLevel: MANAGER
  },

  // user
  userList: {
    path: "/user/list",
    accessLevel: AUTHOR
  },

  userAddProduct: {
    path: "/user/add-product",
    accessLevel: ADMIN
  },

  uploadWebLead: {
    path: "/upload-web-lead",
    accessLevel: ADMIN
  },
  uploadLead: {
    path: "/upload-internal-lead",
    accessLevel: ADMIN
  },

  // users: {
  //   accessLevel: AUTHOR,
  //   learners: {
  //     path: "/users/:userType",
  //     accessLevels: AUTHOR
  //   },
  //   coaches: {
  //     path: "/users/:userType",
  //     accessLevels: AUTHOR
  //   }
  // },

  // product
  productList: {
    path: "/products",
    accessLevel: AUTHOR
  },
  productCreate: {
    path: "/products/create",
    accessLevel: OWNER
  },

  // sessions
  sessionList: {
    path: "/sessions",
    accessLevel: AUTHOR
  },
  sessionCreate: {
    path: "/sessions/create",
    accessLevel: OWNER
  },
  sessionEdit: {
    path: "/session/edit/:sessionId",
    accessLevel: OWNER
  },

  profile: {
    path: "/profile"
  },

  // admin
  adminManagement: {
    path: "/admin",
    accessLevel: OWNER
  },
  adminCreate: {
    path: "/admin/create",
    accessLevel: OWNER
  },

  // referral
  referralList: {
    path: "/referrals/all-referrals",
    accessLevel: AUTHOR
  },
  referralRequest: {
    path: "/referrals/referrals-requests",
    accessLevel: AUTHOR
  },

  // tools
  productStats: {
    path: "/tools/product-stats",
    accessLevel: ADMIN
  },
  mapProductPackages: {
    path: "/tools/map-product-packages",
    accessLevel: ADMIN
  },
  userRectification: {
    path: "/tools/user-rectification",
    accessLevel: ADMIN
  },
  emailToPlatformId: {
    path: "/tools/email-to-platformId",
    accessLevel: MANAGER
  },
  referralStats: {
    path: "/tools/referral-stats",
    accessLevel: ADMIN
  },
  uploadImages: {
    path: "/upload-images",
    accessLevel: ADMIN
  },
  messagingQuota: {
    path: "/messaging-quota",
    accessLevel: ADMIN
  },
  bulkUsersDownload: {
    path: "/tools/bulk-users-download",
    accessLevel: ADMIN
  },
  instituteTags: {
    accessLevel: ADMIN,
    path: "/tools/institute-tags"
  },
  bulkAccountCreation: {
    accessLevel: ADMIN,
    path: "/tools/create-bulk-account"
  },

  // batch management
  pendingBatches: {
    path: "/batch-management/pending-batches",
    accessLevel: AUTHOR
  },
  assignedBatches: {
    path: "/batch-management/assigned-batches",
    accessLevel: AUTHOR
  },
  bulkLmsComponent: {
    path: "/batch-management/bulk-lms-components",
    accessLevel: ADMIN
  },

  // E2E Project assignment
  e2eManagement: {
    accessLevel: ADMIN,
    projects: {
      path: "/e2e-management/projects",
      accessLevel: ADMIN
    },
    bulkProjectAssignment: {
      path: "/e2e-management/bulk-project-assignment",
      accessLevel: ADMIN
    },
    uploadFinalProjectMarks: {
      path: "/e2e-management/upload-final-project-assignment",
      accessLevel: ADMIN
    }
  },
  // company and job management
  companies: {
    path: "/companies",
    accessLevel: ADMIN
  },
  addCompany: {
    path: "/companies/add",
    accessLevel: ADMIN
  },
  editCompany: {
    path: "/companies/edit/:companyId",
    accessLevel: ADMIN
  },
  jobs: {
    path: "/companies/jobs",
    accessLevel: ADMIN
  },
  associateLearner: {
    path: "/companies/jobs/associatelearner/:companyId/:jobId",
    accessLevel: ADMIN
  },

  // lms test management
  tests: {
    path: "/lms/test",
    accessLevel: MANAGER
  },
  addTest: {
    path: "/lms/test/add",
    accessLevel: MANAGER
  },
  viewTestScore: {
    path: "/lms/test/score",
    accessLevel: MANAGER
  },
  detailedResult: {
    path: "/lms/test/score/analysis",
    accessLevel: MANAGER
  },

  learningOperations: {
    overallAnalytics: {
      path: "/analytics/learning-operations/overall-analytics",
      accessLevel: MANAGER
    },
    customerAnalytics: {
      path: "/analytics/learning-operations/customer-analytics",
      accessLevel: MANAGER
    },
    coachingMarketPlaceAnalytics: {
      path: "/analytics/learning-operations/coaching-marketplace-analytics",
      accessLevel: MANAGER
    },
    oneOnOneLearningFoundation: {
      path: "/analytics/learning-operations/one-on-one-coaching-foundation-analytics",
      accessLevel: ADMIN
    }
  },

  Coupon: {
    addCoupon: {
      path: "/coupon",
      accessLevel: OWNER
    },
    editCoupon: {
      path: "/editcoupon/:id",
      pathString: "/editcoupon/",
      accessLevel: OWNER
    },
    showCoupon: {
      path: "/showcoupon",
      accessLevel: OWNER
    }
  },
  // session v3
  CreateLmsSession: {
    path: "/lms-sessions/create",
    accessLevel: AUTHOR
  },

  CommonSessionV3: {
    accessLevels: MANAGER,
    bulkFeedback: {
      path: `${commonSessionUrl}/bulk-feedback-update`,
      accessLevels: MANAGER
    },
    bulkFeedbackGenerate: {
      path: `${commonSessionUrl}/bulk-feedback-generate`,
      accessLevels: MANAGER
    },
    bulkProviderUpdate: {
      path: `${commonSessionUrl}/bulk-provider-update`,
      accessLevels: MANAGER
    },
    videoNotPresentSession: {
      path: `${commonSessionUrl}/video-not-present`,
      accessLevels: MANAGER
    }
  },

  LmsSessions: {
    accessLevels: MANAGER,
    all: {
      path: "/lms-sessions/all",
      accessLevels: MANAGER
    },
    cmsSyncNeeded: {
      path: "/lms-sessions/cms-sync-needed",
      accessLevels: MANAGER
    },
    readyToScheduled: {
      path: "/lms-sessions/ready-to-schedule",
      accessLevels: MANAGER
    },
    scheduled: {
      path: "/lms-sessions/scheduled",
      accessLevels: MANAGER
    },
    ongoing: {
      path: "/lms-sessions/ongoing",
      accessLevels: MANAGER
    },
    completed: {
      path: "/lms-sessions/completed",
      accessLevels: MANAGER
    },
    cancelled: {
      path: "/lms-sessions/cancelled",
      accessLevels: MANAGER
    },
    abandoned: {
      path: "/lms-sessions/abandoned",
      accessLevels: MANAGER
    },
    elapsed: {
      path: "/lms-sessions/elapsed",
      accessLevels: MANAGER
    },
    archived: {
      path: "/lms-sessions/archived",
      accessLevels: ADMIN
    }
  },

  // session details page
  SessionDetails: {
    path: "/lms-sessions/:sessionId"
  },

  //Session 1:1

  sessions1_1: {
    accessLevel: MANAGER,
    all: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/all`
    },
    coachNotAssigned: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/coach-not-assigned`
    },
    readyToSchedule: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/ready-to-schedule`
    },
    pendingTimeSlots: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/pending-time-slots`
    },
    rescheduleRequest: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/reschedule-requests`
    },
    started: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/ongoing`
    },
    cancelled: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/cancelled`
    },
    abandoned: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/abandoned`
    },
    elapsed: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/elapsed`
    },
    completed: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/completed`
    },
    scheduled: {
      accessLevels: MANAGER,
      path: `${personalizedUrl}/scheduled`
    },
    detailsPage: {
      accessLevel: MANAGER,
      path: `${personalizedUrl}/:sessionId`
    },
    doubtSessions: {
      path: "/doubt-sessions",
      accessLevel: MANAGER
    }
  },

  // Join session
  joinSession: {
    path: "/join-session/:sessionId"
  },

  // Generic Sessions
  CreateGenericSession: {
    path: "/generic-sessions/create",
    accessLevel: AUTHOR
  },

  GenericSessions: {
    accessLevels: MANAGER,
    all: {
      path: "/generic-sessions/all",
      accessLevels: MANAGER
    },
    readyToScheduled: {
      path: "/generic-sessions/ready-to-schedule",
      accessLevels: MANAGER
    },
    scheduled: {
      path: "/generic-sessions/scheduled",
      accessLevels: MANAGER
    },
    ongoing: {
      path: "/generic-sessions/ongoing",
      accessLevels: MANAGER
    },
    completed: {
      path: "/generic-sessions/completed",
      accessLevels: MANAGER
    },
    cancelled: {
      path: "/generic-sessions/cancelled",
      accessLevels: MANAGER
    },
    onHold: {
      path: "/generic-sessions/on-hold",
      accessLevels: MANAGER
    },
    abandoned: {
      path: "/generic-sessions/abandoned",
      accessLevels: MANAGER
    },
    elapsed: {
      path: "/generic-sessions/elapsed",
      accessLevels: MANAGER
    },
    archived: {
      path: "/generic-sessions/archived",
      accessLevels: ADMIN
    }
  },

  // generic session details page
  GenericSessionDetails: {
    path: "/generic-sessions/:sessionId",
    accessLevels: MANAGER
  },

  //discussion Forum

  DiscussionForm: {
    RedeemTable: {
      path: "/discussionforum",
      accessLevel: ADMIN
    },
    RedeemList: {
      path: "/discussionforumlist",
      accessLevel: ADMIN
    }
  },

  AlphaBoard: {
    path: "/alphaboard",
    accessLevel: ADMIN
  },

  // lead up
  LeadUp: {
    path: "/leadup",
    accessLevel: LEADUP
  }
};

// Helpers

export default paths;
