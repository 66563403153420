import React, { useState, FunctionComponent, useEffect } from "react";
import classnames from "classnames";
import { AnyAction } from "redux";
import { connect, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Layout, Icon, Tooltip, Popover, Avatar, Divider } from "antd";
import { Location } from "history";
import * as userActions from "app/actions/user";
import { GlobalState } from "app/reducers";
import ExtendScreen from "app/component/ExtendScreen";
import { Sidebar } from "./component";
import styles from "./index.module.scss";
import { getAdminInfo } from "app/services/admin.service";
import _get from "lodash/get";
import { imageUrl } from "app/utils/image";
import ChangePassword from "app/component/ChangePassword";

const { Header, Content } = Layout;

interface IMainLayoutProps {
  location: Location;
  dispatch: ThunkDispatch<any, any, AnyAction>;
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  style?: React.CSSProperties;
  className?: string;
}

const MainLayout: FunctionComponent<IMainLayoutProps> = ({ dispatch, location, children, title, subtitle, style, className }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [passLoading, setPassLoading] = useState<boolean>(false);
  // TODO: handle loading and error cases
  const [adminInfo, setAdminInfo] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(Boolean(Number(localStorage.getItem("biadmin_sidebar"))));

  //Profile info
  const fname = _get(adminInfo, "firstName", "");
  const lname = _get(adminInfo, "lastName", "");
  const accessLevel = _get(adminInfo, "accessLevel", "");

  const profileImage = _get(adminInfo, "profileImages.thumbnail", null) || imageUrl("dummy-male.jpg");

  const toggle = () => {
    localStorage.setItem("biadmin_sidebar", collapsed ? "0" : "1");
    setCollapsed(!collapsed);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await getAdminInfo();
      setAdminInfo(response.data);
      dispatch(userActions.setUserInfo(response?.data));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    return dispatch(userActions.logout());
  };

  useEffect(() => {
    if (!passLoading) {
      getMe();
    }
  }, []);

  const profilePopoverContent = (
    <div className={styles.popContainer}>
      <div onClick={() => setVisible(true)}>
        <Icon type="lock" /> <span className={styles.logoutText}>Change Password</span>
      </div>
      <div className={styles.popItem} onClick={logout}>
        <Icon type="logout" /> <span className={styles.logoutText}>Logout</span>
      </div>
    </div>
  );

  return (
    <Layout className={styles.layout}>
      <Sidebar adminInfo={adminInfo} collapsed={collapsed} />
      <Layout>
        <div className={styles.wrapper}>
          <Header className={collapsed ? styles.headerCollapse : styles.header}>
            <div className={styles.leftSide}>
              <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                <Icon className={styles.trigger} type={collapsed ? "menu-unfold" : "menu-fold"} onClick={toggle} />
              </Tooltip>
            </div>
            <div className={styles.pageTitleContainer}>
              {title && <h1 className={styles.title}>{title}</h1>}
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            </div>
            <div className={styles.rightSide}>
              <ExtendScreen className={styles.extendScreen} />
              {/* <Tooltip title="Logout">
                <Icon className={styles.logout} type="logout" onClick={logout} />
              </Tooltip> */}
              <Popover
                title={
                  <div className={styles.popoverTitle}>
                    <p>
                      {fname} {lname}
                    </p>
                    <em className={styles.popoverTitleAccessLevel}>{accessLevel}</em>
                  </div>
                }
                content={profilePopoverContent}
                placement="bottom"
                trigger="click">
                <div className={styles.profileWrapper}>
                  <Avatar src={profileImage} />
                  <span>{fname}</span>
                </div>
              </Popover>
            </div>
          </Header>
        </div>
        <Content
          className={classnames(
            styles.content,
            {
              [styles.contentCollapse]: collapsed
            },
            className
          )}
          style={style}>
          {children}
        </Content>
        <ChangePassword passLoading={passLoading} setPassLoading={setPassLoading} visible={visible} onCloseModal={() => setVisible(false)} />
      </Layout>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  dispatch
});
const mapStateToProps = (state: GlobalState) => ({
  location: state.router.location
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
