import { API, to } from "../utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const FETCH_MODULES_SCHEMA = "FETCH_MODULES_SCHEMA";
export const FETCH_MODULES_SCHEMA_PENDING = "FETCH_MODULES_SCHEMA_PENDING";
export const FETCH_MODULES_SCHEMA_FULFILLED = "FETCH_MODULES_SCHEMA_FULFILLED";
export const FETCH_MODULES_SCHEMA_REJECTED = "FETCH_MODULES_SCHEMA_REJECTED";

export const FETCH_MODULES = "FETCH_MODULES";
export const FETCH_MODULES_PENDING = "FETCH_MODULES_PENDING";
export const FETCH_MODULES_FULFILLED = "FETCH_MODULES_FULFILLED";
export const FETCH_MODULES_REJECTED = "FETCH_MODULES_REJECTED";

export const FETCH_MODULES_REFRESH = "FETCH_MODULES_REFRESH";
export const FETCH_MODULES_REFRESH_PENDING = "FETCH_MODULES_REFRESH_PENDING";
export const FETCH_MODULES_REFRESH_FULFILLED = "FETCH_MODULES_REFRESH_FULFILLED";
export const FETCH_MODULES_REFRESH_REJECTED = "FETCH_MODULES_REJECTED";

export const ADD_MODULE = "ADD_MODULE";
export const ADD_MODULE_PENDING = "ADD_MODULE_PENDING";
export const ADD_MODULE_FULFILLED = "ADD_MODULE_FULFILLED";
export const ADD_MODULE_REJECTED = "ADD_MODULE_REJECTED";

export const MODULE_CHANGE_VIEW = "MODULE_CHANGE_VIEW";

export const MODULE_ADD_COMPONENT = "MODULE_ADD_COMPONENT";

export const MODULE_REMOVE_COMPONENT = "MODULE_REMOVE_COMPONENT";

export const MODULE_EDIT_COMPONENT = "MODULE_EDIT_COMPONENT";

export const MODULE_CLEAR_COMPONENT = "MODULE_CLEAR_COMPONENT";

export const MODULE_SET_FORM = "MODULE_SET_FORM";
export const MODULE_CLEAR_FORM = "MODULE_CLEAR_FORM";

export const MODULE_SELECT_SCHEMA = "MODULE_SELECT_SCHEMA";
export const MODULE_CLEAR_SELECTED_SCHEMA = "MODULE_CLEAR_SELECTED_SCHEMA";

export const getModuleSchema = () => {
  return {
    type: FETCH_MODULES_SCHEMA,
    payload: API.getRequest("/admin/lms/module-schema").then(data => {
      return {
        schema: data
      };
    })
  };
};

export const getModules = (params: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return new Promise(async (resolve, reject) => {
    const data = await to(API.getRequest("/admin/lms/modules", params));
    if (data && data.error) {
      dispatch({
        type: FETCH_MODULES_REJECTED,
        payload: {
          data
        }
      });
      return reject(data.error);
    }
    dispatch({
      type: FETCH_MODULES_FULFILLED,
      payload: {
        data: data.rows,
        total: data.total
      }
    });
    resolve(data);
  });
};

export const addModule = (body: any) => {
  return {
    type: ADD_MODULE,
    payload: API.postRequest("/admin/lms/module", body).then(data => {
      return {
        component: data
      };
    })
  };
};

export const changeView = (view: number) => {
  return {
    type: MODULE_CHANGE_VIEW,
    payload: {
      view
    }
  };
};

export const addComponent = (selectedComponent: any) => {
  return {
    type: MODULE_ADD_COMPONENT,
    payload: {
      selectedComponent
    }
  };
};

export const clearComponent = () => {
  return {
    type: MODULE_CLEAR_COMPONENT
  };
};

export const removeComponent = (id: string) => {
  return {
    type: MODULE_REMOVE_COMPONENT,
    payload: {
      id
    }
  };
};

export const editComponent = (index: number, dependencies: any) => {
  return {
    type: MODULE_EDIT_COMPONENT,
    payload: {
      index,
      dependencies
    }
  };
};

export const setForm = (data: any) => {
  return {
    type: MODULE_SET_FORM,
    payload: {
      data
    }
  };
};

export const clearForm = () => {
  return {
    type: MODULE_CLEAR_FORM
  };
};

export const setSchema = (selected: any) => {
  return {
    type: MODULE_SELECT_SCHEMA,
    payload: {
      selected
    }
  };
};

export const clearSelectedSchema = () => {
  return {
    type: MODULE_CLEAR_SELECTED_SCHEMA
  };
};
