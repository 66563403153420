import React, { FunctionComponent } from "react";
import ProductCreationForm from "app/container/Product/Form";
import MainLayout from "app/layouts/MainLayout";

const AdminPage: FunctionComponent = () => {
  return (
    <MainLayout title="Create New Product" subtitle="Create a new career coaching product">
      <ProductCreationForm />
    </MainLayout>
  );
};

export default AdminPage;
