import React, { FC, useEffect } from "react";
import "./LoginForm.scss";
import { createLoadingSelector, createErrorMessageSelector, notifications } from "app/utils";
import { Form, Input, Button, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { RouteComponentProps } from "react-router";
import { withNaming } from "@bem-react/classname";
import { connect } from "react-redux";
import { GlobalState } from "app/reducers";
import { AnyAction } from "redux";
import * as userActions from "app/actions/user";
import { ThunkDispatch } from "redux-thunk";

const cn = withNaming({ e: "__", m: "--" })("Form");

interface IForm extends FormComponentProps, RouteComponentProps<any> {
  loading: boolean;
  error: any;
  dispatch: ThunkDispatch<any, any, AnyAction>;
}

const LoginForm: FC<IForm> = ({ form, loading, dispatch, error }) => {
  const { getFieldDecorator } = form;

  const onSubmit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    form.validateFields(async (err: any, values: { id: string; password: string }) => {
      if (!err) {
        dispatch(userActions.login(values.id, values.password));
      }
    });
  };

  useEffect(() => {
    if (error[userActions.LOGIN_USER]) {
      notifications("error", error[userActions.LOGIN_USER]);
    }
  }, [error]);
  return (
    <Form onSubmit={onSubmit} className={cn()}>
      <Form.Item>
        {getFieldDecorator("id", {
          rules: [
            {
              required: true,
              message: "Please input your Email!"
            }
          ]
        })(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Username" className={cn("input")} />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [{ required: true, message: "Please input your Password!" }]
        })(<Input prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />} type="password" placeholder="Password" className={cn("input")} />)}
      </Form.Item>
      <Button loading={loading} className={cn("button")} type="primary" htmlType="submit">
        Log in
      </Button>
    </Form>
  );
};
const loadingSelector = createLoadingSelector([userActions.LOGIN_USER]);
const errorSelector = createErrorMessageSelector([userActions.LOGIN_USER]);
const mapStateToProps = (state: GlobalState) => {
  return {
    loading: loadingSelector(state.loading),
    error: errorSelector(state.error)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    dispatch
  };
};

export default Form.create({ name: "login" })(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
