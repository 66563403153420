import * as actions from "../actions/courses";
import { ICourses } from "app/interfaces/courses.interface";

const initialState: ICourses = {
  schema: [],
  data: [],
  rows: 0,
  view: 0,
  selectedModules: [],
  form: {}
};

const modules = (state = initialState, action: any) => {
  let selectedModules;

  switch (action.type) {
    case actions.FETCH_COURSES_FULFILLED:
      const data = [...state.data, ...action.payload.data];
      return { ...state, data, rows: action.payload.total };

    case actions.COURSE_CHANGE_VIEW:
      return { ...state, view: action.payload.view };

    case actions.COURSE_ADD_MODULE:
      return {
        ...state,
        selectedModules: [...state.selectedModules, action.payload.selectedModule]
      };

    case actions.COURSE_CLEAR_MODULE:
      return {
        ...state,
        selectedModule: []
      };

    case actions.COURSE_REMOVE_MODULE:
      const index = parseInt(action.payload.id.split("-")[1], 10);
      selectedModules = state.selectedModules.filter((s, i) => i !== index);
      selectedModules = selectedModules.map(s => {
        if (s.dependencies && Array.isArray(s.dependencies)) {
          let dep = s.dependencies.filter((d: string) => d !== action.payload.id);
          dep = dep.map((d: string) => {
            const temp = d.split("-");
            if (index < parseInt(temp[1], 10)) {
              temp[1] = (parseInt(temp[1], 10) - 1).toString();
            }
            return temp.join("-");
          });
          return { ...s, dependencies: dep };
        }
        return s;
      });
      return {
        ...state,
        selectedModules
      };

    case actions.COURSE_EDIT_MODULE:
      selectedModules = state.selectedModules.map((s, i) => {
        if (i === action.payload.index) {
          return {
            ...s,
            dependencies: action.payload.dependencies
          };
        }
        return s;
      });
      return {
        ...state,
        selectedModules
      };

    case actions.COURSE_SET_FORM:
      const form = { ...state.form, ...action.payload.data };
      return { ...state, form };

    case actions.COURSE_CLEAR_FORM:
      return { ...state, form: {} };

    default:
      return state;
  }
};

export default modules;
