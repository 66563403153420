import React, { FunctionComponent } from "react";
import MessagingQuota from "app/container/Tools/MessagingQuota/messaging-quota";
import MainLayout from "app/layouts/MainLayout";

const MessageQuota: FunctionComponent = () => {
  return (
     <MainLayout title="Messaging Quota" subtitle="All messaging services quota">
          <MessagingQuota />
      </MainLayout>
  );
};

export default MessageQuota;
