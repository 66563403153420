const config = Object.freeze({
  API_ENDPOINT: process.env.REACT_APP_API_BASE_URL,
  AUTH_API_ENDPOINT: process.env.REACT_APP_AUTH_API_BASE_URL,
  API_ENDPOINT_V3: process.env.REACT_APP_API_V3_BASE_URL,
  API_LMS_NODE: process.env.REACT_APP_API_LMS_NODE || "https://lms-api.boardinfinity.com/api",
  LMS_API_ENDPOINT: process.env.REACT_APP_API_LMS || "https://lms-api.boardinfinity.com",

  CDN: "https://cdn.boardinfinity.com",
  FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL || "https://www.boardinfinity.com",
  TESTING_INSTANCE: process.env.REACT_APP_TESTING_INSTANCE || "newton",
  MONGO_CHART: process.env.REACT_APP_COACHING_MARKETPLACE_MONGO_CHART,
  COMPANY_FRONTEND_URL: process.env.REACT_APP_COMPANY_PORTAL_URL || "https://boardinfinity.com/admin",
  ENVIRONMENT: process.env.REACT_APP_ENV || "development",
  H100MSBaseURL: process.env.REACT_APP_API_H100MSLink || "https://100ms.boardinfinity.net"
});

export default config;
