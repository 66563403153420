import React from "react";
import "./App.scss";
import Routes from "./Routes";
import { withNaming } from "@bem-react/classname";

const cn = withNaming({ e: "__", m: "--" })("App");
const App = () => {
  return (
    <div className={cn()}>
      <Routes />
    </div>
  );
};

export default App;
