import { Dispatch } from "redux";
import { apiInstance } from "app/utils/api";
import { getAuthHeaders } from "app/utils/authHeader";

export const getInstitutes = async () => {
  const res = await apiInstance.get("/master-list/institutes?limit=100000");
  return res.data;
};

export const getCompanies = async () => {
  const res = await apiInstance.get("/master-list/companies?limit=100000");
  return res.data;
};

export const getDegrees = async () => {
  const res = await apiInstance.get("/master-list/degrees?limit=100000");
  return res.data;
};

export const getDesignations = async () => {
  const res = await apiInstance.get("/master-list/designations?limit=100000");
  return res.data;
};

export const getLanguages = async () => {
  const res = await apiInstance.get("/master-list/languages?limit=100000");
  return res.data;
};

export const getSkills = async () => {
  const res = await apiInstance.get("/master-list/skills?limit=100000");
  return res.data;
};

export const getInterests = async () => {
  const res = await apiInstance.get("/master-list/interests?limit=100000");
  return res.data;
};

export const getEnums = async () => {
  const res = await apiInstance.get("/misc/enums");
  return res.data;
};

export const getCountryState = async () => {
  const res = await apiInstance.get("/master-list/country-state-city?limit=100000");
  return res.data;
};

export const getTag = async () => {
  const res = await apiInstance.get("/master-list/tags?limit=100000");
  return res.data;
};

export const getRoles = async () => {
  const res = await apiInstance.get("/master-list/roles?limit=100000");
  return res.data;
};

export const getSectors = async () => {
  const res = await apiInstance.get("/master-list/sectors?limit=100000");
  return res.data;
};

// student roles
export const getStudentRoles = async () => {
  const res = await apiInstance.get("/master-list/studentRoles?limit=100000");
  return res.data;
};

/* Master list api */
export const getMasterListDataFromSlug = async (Slug: any) => {
  const res = await apiInstance.get("/admin/master-list/getAllBySlugs", {
    params: {
      Slug
    }
  });
  return res.data.data.data;
};
