import { API, to } from "../utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const FETCH_COMPONENTS_SCHEMA = "FETCH_COMPONENTS_SCHEMA";
export const FETCH_COMPONENTS_SCHEMA_PENDING = "FETCH_COMPONENTS_SCHEMA_PENDING";
export const FETCH_COMPONENTS_SCHEMA_FULFILLED = "FETCH_COMPONENTS_SCHEMA_FULFILLED";
export const FETCH_COMPONENTS_SCHEMA_REJECTED = "FETCH_COMPONENTS_SCHEMA_REJECTED";

export const FETCH_COMPONENTS = "FETCH_COMPONENTS";
export const FETCH_COMPONENTS_PENDING = "FETCH_COMPONENTS_PENDING";
export const FETCH_COMPONENTS_FULFILLED = "FETCH_COMPONENTS_FULFILLED";
export const FETCH_COMPONENTS_REJECTED = "FETCH_COMPONENTS_REJECTED";

export const ADD_COMPONENT = "ADD_COMPONENT";
export const ADD_COMPONENT_PENDING = "ADD_COMPONENT_PENDING";
export const ADD_COMPONENT_FULFILLED = "ADD_COMPONENT_FULFILLED";
export const ADD_COMPONENT_REJECTED = "ADD_COMPONENT_REJECTED";

export const COMPONENT_SET_FORM = "COMPONENT_SET_FORM";
export const COMPONENT_CLEAR_FORM = "COMPONENT_CLEAR_FORM";

export const COMPONENT_SELECT_SCHEMA = "COMPONENT_SELECT_SCHEMA";
export const COMPONENT_CLEAR_SELECTED_SCHEMA = "COMPONENT_CLEAR_SELECTED_SCHEMA";

export const getComponentSchema = () => {
  return {
    type: FETCH_COMPONENTS_SCHEMA,
    payload: API.getRequest("/admin/lms/component-schema").then(data => {
      return {
        schema: data
      };
    })
  };
};

export const getComponents = (params: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return new Promise(async (resolve, reject) => {
    const data = await to(API.getRequest("/admin/lms/components", params));
    if (data && data.error) {
      dispatch({
        type: FETCH_COMPONENTS_REJECTED,
        payload: {
          data
        }
      });
      return reject(data.error);
    }
    dispatch({
      type: FETCH_COMPONENTS_FULFILLED,
      payload: {
        data: data.rows,
        total: data.total
      }
    });
    resolve(data);
  });
};

export const addComponent = (body: any) => {
  return {
    type: ADD_COMPONENT,
    payload: API.postRequest("/admin/lms/component", body).then(data => {
      return {
        component: data
      };
    })
  };
};

export const setForm = (data: any) => {
  return {
    type: COMPONENT_SET_FORM,
    payload: {
      data
    }
  };
};

export const clearForm = () => {
  return {
    type: COMPONENT_CLEAR_FORM
  };
};

export const setSchema = (selected: any) => {
  return {
    type: COMPONENT_SELECT_SCHEMA,
    payload: {
      selected
    }
  };
};

export const clearSelectedSchema = () => {
  return {
    type: COMPONENT_CLEAR_SELECTED_SCHEMA
  };
};
