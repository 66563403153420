import React, { useState, FunctionComponent } from "react";
import classnames from "classnames";
import smallScreen from "assets/svg/smallScreen.svg";
import fullScreen from "assets/svg/fullScreen.svg";
import style from "./index.module.scss";
import { Tooltip } from "antd";

interface IProps {
  className?: string;
  styles?: string;
}

const ExtendScreen: FunctionComponent<IProps> = ({ className, ...props }) => {
  const [fs, setFs] = useState(false);
  const classes = classnames(style.extendScreen, className);

  const toggleFs = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl).then(() => setFs(true));
    } else {
      cancelFullScreen.call(doc).then(() => setFs(false));
    }
  };

  return (
    <Tooltip title={fs ? "Exit Fullscreen" : "Fullscreen"}>
      <button onClick={toggleFs} className={classes} {...props}>
        <img src={fullScreen} alt="fullscreen-icon" style={{ display: fs ? "none" : "block" }} />
        <img src={smallScreen} alt="smallscreen-icon" style={{ display: fs ? "block" : "none" }} />
      </button>
    </Tooltip>
  );
};

export default ExtendScreen;
