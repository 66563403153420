import { logout } from "../actions/user";

export const authentication = (store: any) => (next: any) => async (action: any) => {
  if (action.type.includes("_REJECTED")) {
    if (action.payload && action.payload.response && action.payload.response.status === 401) {
      await store.dispatch(logout());
      window.location.href = "/";
    }
  }
  return next(action);
};
