import React, { FC, lazy } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { GlobalState } from "./reducers";
import { lazyLoad, retry } from "./utils";
// import MainLayout from "./layouts/MainLayout";

// Routes
import NotFound from "app/pages/404";
import Login from "app/container/Login";
import Product from "app/pages/products/index";
import ProductCreationForm from "app/pages/products/create";
import Session from "app/pages/sessions/index";
import SessionCreationForm from "app/pages/sessions/create";
import paths from "./utils/paths";
import { isAccessLevel } from "./utils/accessLevel/accessLevel";
import MessageQuota from "./pages/tools/messaging-quota";

const Dashboard = lazy(() => retry(() => import("app/pages/dashboard")));

// LMS
// const Components = lazy(() => retry(() => import("app/container/LMS/container/Components")));
// const Courses = lazy(() => retry(() => import("app/container/LMS/container/Courses")));
// const Modules = lazy(() => retry(() => import("app/container/LMS/container/Modules")));

// Personalized Sessions
const AllSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/all")));
const PendingSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/pending")));
const UpcomingSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/upcoming")));
const ScheduledSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/scheduled")));
const FeedbackPendingSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/feedback-pending")));
const CompletedSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/completed")));
const OngoingSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/ongoing")));
const ElapsedSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/elapsed")));
const RescheduleRequestsSessions = lazy(() => retry(() => import("app/pages/scheduling/sessions/reschedule-requests")));
const DoubtSessionsCreation = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/createDoubtSessions")));
// const RescheduledSessions = lazy(() => retry(() => import("app/pages/Scheduling/Sessions/RescheduledSessions")));
// const CancelledSessions = lazy(() => retry(() => import("app/pages/Scheduling/Sessions/CancelledSessions")));

// Webinars
const Webinars = lazy(() => retry(() => import("app/pages/webinars")));
const WebinarDetailsPage = lazy(() => import("app/pages/webinars/webinarDetailsPage"));
// UserList
const UserList = lazy(() => retry(() => import("app/pages/user/list")));

// Admin Management
const AdminListData = lazy(() => retry(() => import("app/pages/admin/index")));
const AdminCreateForm = lazy(() => retry(() => import("app/pages/admin/create")));

//Profile
const ProfilePage = lazy(() => retry(() => import("app/pages/profile/index")));

// Coach Invite
const CoachForm = lazy(() => retry(() => import("app/pages/coach-invite")));

// User Tools
const UserAddProduct = lazy(() => retry(() => import("app/pages/user/add-product")));

// Upload lead
const UploadLead = lazy(() => retry(() => import("app/pages/uploadLead/index")));
const UploadWebLeadPage = lazy(() => retry(() => import("app/pages/uploadLead/webLeadUpload")));

// tools
const uploadImages = lazy(() => import("app/pages/tools/upload-image"));
const MapProductPackages = lazy(() => retry(() => import("app/pages/tools/map-product-packages")));
const ProductStats = lazy(() => retry(() => import("app/pages/tools/product-stats")));
const UserRectification = lazy(() => retry(() => import("app/pages/tools/user-rectification")));
const EmailToPlatformId = lazy(() => retry(() => import("app/pages/tools/email-to-platformId")));
const ReferralStats = lazy(() => retry(() => import("app/pages/tools/referral-stats")));
const B2BBulkUserCreation = lazy(() => retry(() => import("app/pages/tools/bTob-bulk-user-creation")));
const BulkUserDownload = lazy(() => retry(() => import("app/pages/tools/bulk-user-download")));
const InstituteTags = lazy(() => retry(() => import("app/pages/tools/institute-tags")));
// referral
const ReferralsRequests = lazy(() => retry(() => import("app/pages/referrals/referrals-requests")));
const AllReferrals = lazy(() => retry(() => import("app/pages/referrals/all-referrals")));

// batch-management
const AssignedBatches = lazy(() => retry(() => import("app/pages/batchManagement/assigned-batches")));
const PendingBatches = lazy(() => retry(() => import("app/pages/batchManagement/pending-batches")));
const BulkLmsComponents = lazy(() => retry(() => import("app/pages/batchManagement/BulkLmsComponent")));

// E2E Management
const E2EProjects = lazy(() => retry(() => import("app/pages/e2eManagement/E2EProjects")));
const BulkProjectAssignment = lazy(() => retry(() => import("app/pages/e2eManagement/E2EManagement")));
const UploadFinalProjectMarks = lazy(() => retry(() => import("app/pages/e2eManagement/UploadFinalProjectMarks")));
// test-management
const TestManagement = lazy(() => retry(() => import("app/pages/testManagement")));
const AddTest = lazy(() => retry(() => import("app/pages/testManagement/add")));
const TestScore = lazy(() => retry(() => import("app/pages/testManagement/viewTestScore")));
const Analysis = lazy(() => import("./container/TestManagement/Analysis"));

// analytics
const OverallAnalytics = lazy(() => retry(() => import("app/pages/analytics/learning-operations/overall-analytics")));
const CustomerAnalytics = lazy(() => retry(() => import("app/pages/analytics/learning-operations/customer-analytics")));
const CoachingMarketplaceAnalytics = lazy(() => retry(() => import("app/pages/analytics/learning-operations/coaching-marketplace-analytics")));

// Company and Job Management
const Companies = lazy(() => retry(() => import("app/pages/companies/index")));
const AddCompany = lazy(() => retry(() => import("app/pages/companies/add")));

const JobPage = lazy(() => retry(() => import("app/pages/companies/jobs/jobspage")));
const AssociateLearnerPage = lazy(() => retry(() => import("app/pages/companies/jobs/AssociateLearner/AssociateLearnerPage")));
const CompanyEdit = lazy(() => retry(() => import("app/pages/companies/edit")));

//coupon
const CouponForm = lazy(() => retry(() => import("app/pages/coupon/CouponForm")));
const CouponTable = lazy(() => retry(() => import("app/pages/coupon/couponTable")));
const CouponEdit = lazy(() => retry(() => import("app/pages/coupon/CouponEdit")));

//1:1 Market Place
const OneonOneCoachingFoundationAnalytics = lazy(() => retry(() => import("app/pages/analytics/learning-operations/one-on-one-coaching-foundation-analytics")));

//session v3
// Create LMS session
const CreateLMSSession = lazy(() => retry(() => import("app/pages/scheduling/lms-session/createSession")));
//LMS Sessions
const AllLmsSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/all")));
const LmsScheduledSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/scheduled")));
const LmsOngoingSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/ongoing")));
const LmsCompletedSessionPages = lazy(() => retry(() => import("app/pages/scheduling/lms-session/completed")));
const CancelledSessionsPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/cancelled")));
const LmsAbandonedSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/abandoned")));
const LmsElapsedSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/elapsed")));
const SessionDetails = lazy(() => retry(() => import("app/pages/scheduling/lms-session/sessionDetails")));
const ReadyToScheduledSessionPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/readyToScheduled")));
const CmsSyncPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/cmsSyncNeeded")));
const ArchivedPage = lazy(() => retry(() => import("app/pages/scheduling/lms-session/archived")));

// Session v3 common
const SessionBulkFeedbackUpdate = lazy(() => retry(() => import("app/pages/sessionv3/common/bulkFeedbackUpdate")));
const SessionBulkFeedbackGeneration = lazy(() => retry(() => import("app/pages/sessionv3/common/feedbackGeneration")));
const bulkProviderUpdate = lazy(() => retry(() => import("app/pages/sessionv3/common/bulkProviderUpdate")));
const videoNotPresentSession = lazy(() => retry(() => import("app/pages/sessionv3/common/videoNotPresentList")));

// 1:1 sessions
const AllSessionsPage = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/AllSessions")));

const Scheduled = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Scheduled")));

const CompletedSessionsPage = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Completed")));

const ReadyToSchedule = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/ReadyToSchedule")));
const CoachNotAssigned = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/CoachNotAssigned")));
const Elapsed = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Elpased")));
const Ongoing = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Ongoing")));
const Abandoned = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Abandoned")));
const Cancelled = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/Cacelled")));
const RescheduleRequest = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/RescheduleRequest")));
const PendingTimeSlots = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/PendingTimeslots")));
const DetailsPage = lazy(() => retry(() => import("app/pages/sessionv3/personalisedSession/DetailsPage")));

// Create Generic session
const CreateGenericSession = lazy(() => retry(() => import("app/pages/genericSessions/createGenericSession")));
// Generic Sessions
const AllGenericSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/all")));
const GenericScheduledSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/scheduled")));
const GenericOngoingSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/ongoing")));
const GenericCompletedSessionPages = lazy(() => retry(() => import("app/pages/genericSessions/completed")));
const CancelledGenericSessionsPage = lazy(() => retry(() => import("app/pages/genericSessions/cancelled")));
const OnHoldGenericSessionsPage = lazy(() => retry(() => import("app/pages/genericSessions/onHold")));
const GenericAbandonedSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/abandoned")));
const GenericElapsedSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/elapsed")));
const GenericSessionDetails = lazy(() => retry(() => import("app/pages/scheduling/lms-session/sessionDetails")));
const ReadyToScheduledGenericSessionPage = lazy(() => retry(() => import("app/pages/genericSessions/readyToScheduled")));
const GenericArchivedPage = lazy(() => retry(() => import("app/pages/genericSessions/archived")));

// Join Session
const JoinSession = lazy(() => retry(() => import("app/pages/joinSession/JoinSession")));

//DiscussionForumTabel
const RedeemTable = lazy(() => retry(() => import("app/pages/DiscussionForum/RedeemTable")));
const RedeemList = lazy(() => retry(() => import("app/pages/DiscussionForum/RedeemList")));

//AlphaBoard
const AlphaBoard = lazy(() => retry(() => import("app/pages/alphaboard/alphaboard")));

// Component which renders route based on access level
// TODO: Figure out a way to make this work - shows nothing for routes below this when access level is not proper
// interface IAccessLevelRoute extends RouteProps {
//   accessLevel: any;
// }

// const AccessLevelRoute: FunctionComponent<IAccessLevelRoute> = ({ accessLevel, ...props }) => {
//   if (isAccessLevel(accessLevel)) {
//     return <Route {...props} />;
//   }
//   return <></>;
// };

//LeadUp
const LeadUp = lazy(() => retry(() => import("app/pages/leadUp/index")));

// All Routes
const Routes: FC<{ token: string }> = ({ token }) => {
  /* when token expires, delete the token from localstorage
   * and redirect them to /login
   */
  if (!token) {
    return (
      <React.Fragment>
        <Route exact={true} path="/login" component={Login} />
        <Redirect from="*" to="/login" />
      </React.Fragment>
    );
  }

  return (
    <Switch>
      <Redirect exact={true} from={paths.home.path} to={paths.dashboard.path} />
      <Redirect exact={true} from={paths.login.path} to={paths.dashboard.path} />
      {/* Views for different access level are handled inside */}
      {isAccessLevel(paths.dashboard.accessLevel) && <Route exact={true} path={paths.dashboard.path} component={lazyLoad(Dashboard)} />}
      {/* LMS Routes */}
      {/* <Route exact path='/lms/components' component={lazyLoad(Components)} />
      <Route exact path='/lms/modules' component={lazyLoad(Modules)} />
      <Route exact path='/lms/courses' component={lazyLoad(Courses)} /> */}
      {/* Session Routes */}
      {isAccessLevel(paths.allSessions.accessLevel) && <Route exact={true} path={paths.allSessions.path} component={lazyLoad(AllSessions)} />}
      {isAccessLevel(paths.pendingSessions.accessLevel) && <Route exact={true} path={paths.pendingSessions.path} component={lazyLoad(PendingSessions)} />}
      {isAccessLevel(paths.upcomingSessions.accessLevel) && <Route exact={true} path={paths.upcomingSessions.path} component={lazyLoad(UpcomingSessions)} />}
      {isAccessLevel(paths.scheduledSessions.accessLevel) && <Route exact={true} path={paths.scheduledSessions.path} component={lazyLoad(ScheduledSessions)} />}
      {isAccessLevel(paths.feedbackPending.accessLevel) && (
        <Route exact={true} path={paths.feedbackPending.path} component={lazyLoad(FeedbackPendingSessions)} />
      )}
      {isAccessLevel(paths.completedSessions.accessLevel) && <Route exact={true} path={paths.completedSessions.path} component={lazyLoad(CompletedSessions)} />}
      {isAccessLevel(paths.ongoingSessions.accessLevel) && <Route exact={true} path={paths.ongoingSessions.path} component={lazyLoad(OngoingSessions)} />}
      {isAccessLevel(paths.elapsedSessions.accessLevel) && <Route exact={true} path={paths.elapsedSessions.path} component={lazyLoad(ElapsedSessions)} />}
      {isAccessLevel(paths.rescheduleRequestsSessions.accessLevel) && (
        <Route exact={true} path={paths.rescheduleRequestsSessions.path} component={lazyLoad(RescheduleRequestsSessions)} />
      )}
      {/* <Route
        exact
        path='/sessions/rescheduled-sessions'
        component={lazyLoad(RescheduledSessions)}
      />
      <Route
        exact
        path='/sessions/cancelled-sessions'
        component={lazyLoad(CancelledSessions)}
      /> */}
      {/* Webinar Routes */}
      {isAccessLevel(paths.webinars.accessLevel) && <Route exact={true} path={paths.webinars.path} component={lazyLoad(Webinars)} />}
      {isAccessLevel(paths.webinarDetails.accessLevel) && <Route exact path={paths.webinarDetails.path} component={lazyLoad(WebinarDetailsPage)} />}
      {/* TODO: Remove this */}
      {isAccessLevel(paths.userList.accessLevel) && <Route exact={true} path={paths.userList.path} component={lazyLoad(UserList)} />}
      {/* {isAccessLevel(paths.userList.accessLevel) && <Route exact={true} path={paths.users.learners.path} component={lazyLoad(UserList)} />}
      {isAccessLevel(paths.userList.accessLevel) && <Route exact={true} path={paths.users.coaches.path} component={lazyLoad(UserList)} />} */}
      {isAccessLevel(paths.adminManagement.accessLevel) && <Route exact={true} path={paths.adminManagement.path} component={lazyLoad(AdminListData)} />}
      {isAccessLevel(paths.adminCreate.accessLevel) && <Route exact={true} path={paths.adminCreate.path} component={lazyLoad(AdminCreateForm)} />}
      <Route exact path={paths.profile.path} component={lazyLoad(ProfilePage)} />
      {isAccessLevel(paths.coachInvite.accessLevel) && <Route exact={true} path={paths.coachInvite.path} component={lazyLoad(CoachForm)} />}
      {isAccessLevel(paths.productList.accessLevel) && <Route exact={true} path={paths.productList.path} component={lazyLoad(Product)} />}
      {isAccessLevel(paths.productCreate.accessLevel) && <Route exact={true} path={paths.productCreate.path} component={lazyLoad(ProductCreationForm)} />}
      {isAccessLevel(paths.sessionList.accessLevel) && <Route exact={true} path={paths.sessionList.path} component={lazyLoad(Session)} />}
      {isAccessLevel(paths.sessionCreate.accessLevel) && <Route exact={true} path={paths.sessionCreate.path} component={lazyLoad(SessionCreationForm)} />}
      {isAccessLevel(paths.sessionEdit.accessLevel) && (
        <Route exact={true} path={paths.sessionEdit.path} component={() => <SessionCreationForm edit={true} />} />
      )}
      {/* for adding product to a user */}
      {isAccessLevel(paths.userAddProduct.accessLevel) && <Route exact={true} path={paths.userAddProduct.path} component={lazyLoad(UserAddProduct)} />}
      {/* Upload Lead */}
      {isAccessLevel(paths.uploadLead.accessLevel) && <Route exact path={paths.uploadLead.path} component={lazyLoad(UploadLead)} />}
      {isAccessLevel(paths.uploadWebLead.accessLevel) && <Route exact path={paths.uploadWebLead.path} component={lazyLoad(UploadWebLeadPage)} />}
      {/* message quota */}
      {isAccessLevel(paths.messagingQuota.accessLevel) && <Route exact path={paths.messagingQuota.path} component={lazyLoad(MessageQuota)} />}
      {/* Referrals */}
      {isAccessLevel(paths.referralList.accessLevel) && <Route exact={true} path={paths.referralList.path} component={lazyLoad(AllReferrals)} />}
      {isAccessLevel(paths.referralRequest.accessLevel) && <Route exact={true} path={paths.referralRequest.path} component={lazyLoad(ReferralsRequests)} />}
      {/* Tools */}
      {isAccessLevel(paths.uploadImages.accessLevel) && <Route exact path={paths.uploadImages.path} component={lazyLoad(uploadImages)} />}
      {isAccessLevel(paths.mapProductPackages.accessLevel) && (
        <Route exact={true} path={paths.mapProductPackages.path} component={lazyLoad(MapProductPackages)} />
      )}
      {isAccessLevel(paths.bulkAccountCreation.accessLevel) && <Route exact path={paths.bulkAccountCreation.path} component={lazyLoad(B2BBulkUserCreation)} />}
      {isAccessLevel(paths.productStats.accessLevel) && <Route exact={true} path={paths.productStats.path} component={lazyLoad(ProductStats)} />}
      {isAccessLevel(paths.userRectification.accessLevel) && <Route exact={true} path={paths.userRectification.path} component={lazyLoad(UserRectification)} />}
      {isAccessLevel(paths.emailToPlatformId.accessLevel) && <Route exact={true} path={paths.emailToPlatformId.path} component={lazyLoad(EmailToPlatformId)} />}
      {isAccessLevel(paths.referralStats.accessLevel) && <Route exact={true} path={paths.referralStats.path} component={lazyLoad(ReferralStats)} />}
      {isAccessLevel(paths.bulkUsersDownload.accessLevel) && <Route exact={true} path={paths.bulkUsersDownload.path} component={lazyLoad(BulkUserDownload)} />}
      {isAccessLevel(paths.instituteTags.accessLevel) && <Route exact path={paths.instituteTags.path} component={lazyLoad(InstituteTags)} />}
      {/* Batch Management */}
      {isAccessLevel(paths.pendingBatches.accessLevel) && <Route exact={true} path={paths.pendingBatches.path} component={lazyLoad(PendingBatches)} />}
      {isAccessLevel(paths.assignedBatches.accessLevel) && <Route exact={true} path={paths.assignedBatches.path} component={lazyLoad(AssignedBatches)} />}
      {isAccessLevel(paths?.bulkLmsComponent?.accessLevel) && <Route exact path={paths.bulkLmsComponent.path} component={lazyLoad(BulkLmsComponents)} />})
      {/* E2E Management */}
      {isAccessLevel(paths.e2eManagement.projects) && <Route exact path={paths.e2eManagement.projects.path} component={lazyLoad(E2EProjects)} />}
      {isAccessLevel(paths.e2eManagement.bulkProjectAssignment.accessLevel) && (
        <Route exact path={paths.e2eManagement.bulkProjectAssignment.path} component={lazyLoad(BulkProjectAssignment)} />
      )}
      {isAccessLevel(paths.e2eManagement.uploadFinalProjectMarks.accessLevel) && (
        <Route exact path={paths.e2eManagement.uploadFinalProjectMarks.path} component={lazyLoad(UploadFinalProjectMarks)} />
      )}
      {/* lms test management */}
      {isAccessLevel(paths.tests.accessLevel) && <Route exact={true} path={paths.tests.path} component={lazyLoad(TestManagement)} />}
      {isAccessLevel(paths.tests.accessLevel) && <Route exact={true} path={paths.addTest.path} component={lazyLoad(AddTest)} />}
      {isAccessLevel(paths.tests.accessLevel) && <Route exact={true} path={paths.viewTestScore.path} component={lazyLoad(TestScore)} />}
      {isAccessLevel(paths.tests.accessLevel) && <Route exact={true} path={paths.detailedResult.path} component={lazyLoad(Analysis)} />}
      {/* Analytics */}
      {isAccessLevel(paths.learningOperations.overallAnalytics.path) && (
        <Route exact={true} path={paths.learningOperations.overallAnalytics.path} component={lazyLoad(OverallAnalytics)} />
      )}
      {isAccessLevel(paths.learningOperations.customerAnalytics.path) && (
        <Route exact={true} path={paths.learningOperations.customerAnalytics.path} component={lazyLoad(CustomerAnalytics)} />
      )}
      {isAccessLevel(paths.learningOperations.coachingMarketPlaceAnalytics.path) && (
        <Route exact={true} path={paths.learningOperations.coachingMarketPlaceAnalytics.path} component={lazyLoad(CoachingMarketplaceAnalytics)} />
      )}
      {/*coupon */}
      {isAccessLevel(paths.Coupon.addCoupon.accessLevel) && <Route exact={true} path={paths.Coupon.addCoupon.path} component={lazyLoad(CouponForm)} />}
      {isAccessLevel(paths.Coupon.showCoupon.accessLevel) && <Route exact={true} path={paths.Coupon.showCoupon.path} component={lazyLoad(CouponTable)} />}
      {isAccessLevel(paths.Coupon.editCoupon) && <Route exact={true} path={paths.Coupon.editCoupon.path} component={lazyLoad(CouponEdit)} />}
      {/**1:1 market place */}
      {isAccessLevel(paths.learningOperations.oneOnOneLearningFoundation.accessLevel) && (
        <Route exact={true} path={paths.learningOperations.oneOnOneLearningFoundation.path} component={lazyLoad(OneonOneCoachingFoundationAnalytics)} />
      )}
      {/**DiscussionForum Table */}
      {isAccessLevel(paths.DiscussionForm.RedeemTable.accessLevel) && (
        <Route exact={true} path={paths.DiscussionForm.RedeemTable.path} component={lazyLoad(RedeemTable)} />
      )}
      {isAccessLevel(paths.DiscussionForm.RedeemList.accessLevel) && (
        <Route exact={true} path={paths.DiscussionForm.RedeemList.path} component={lazyLoad(RedeemList)} />
      )}
      {/* Companies */}
      {isAccessLevel(paths.companies.accessLevel) && <Route exact={true} path={paths.companies.path} component={lazyLoad(Companies)} />}
      {isAccessLevel(paths.addCompany.accessLevel) && <Route exact={true} path={paths.addCompany.path} component={lazyLoad(AddCompany)} />}
      {isAccessLevel(paths.jobs.accessLevel) && <Route exact={true} path={paths.jobs.path} component={lazyLoad(JobPage)} />}
      {isAccessLevel(paths.associateLearner.accessLevel) && (
        <Route exact={true} path={paths.associateLearner.path} component={lazyLoad(AssociateLearnerPage)} />
      )}
      {isAccessLevel(paths.editCompany.accessLevel) && <Route exact={true} path={paths.editCompany.path} component={lazyLoad(CompanyEdit)} />}
      {/* session v-3 */}
      {/* Common bulk feedback update */}
      {isAccessLevel(paths.CommonSessionV3.bulkFeedback.accessLevels) && (
        <Route exact={true} path={paths.CommonSessionV3.bulkFeedback.path} component={lazyLoad(SessionBulkFeedbackUpdate)} />
      )}
      {isAccessLevel(paths.CommonSessionV3.bulkFeedbackGenerate.accessLevels) && (
        <Route exact={true} path={paths.CommonSessionV3.bulkFeedbackGenerate.path} component={lazyLoad(SessionBulkFeedbackGeneration)} />
      )}
      {isAccessLevel(paths.CommonSessionV3.bulkProviderUpdate.accessLevels) && (
        <Route exact={true} path={paths.CommonSessionV3.bulkProviderUpdate.path} component={lazyLoad(bulkProviderUpdate)} />
      )}
      {isAccessLevel(paths.CommonSessionV3.videoNotPresentSession.accessLevels) && (
        <Route exact={true} path={paths.CommonSessionV3.videoNotPresentSession.path} component={lazyLoad(videoNotPresentSession)} />
      )}
      {/* Create Lms Session  */}
      {isAccessLevel(paths.CreateLmsSession.accessLevel) && <Route exact path={paths.CreateLmsSession.path} component={lazyLoad(CreateLMSSession)} />}
      {/* LMS session */}
      {isAccessLevel(paths.LmsSessions.all.accessLevels) && <Route exact path={paths.LmsSessions.all.path} component={lazyLoad(AllLmsSessionPage)} />}
      {isAccessLevel(paths.LmsSessions.readyToScheduled.accessLevels) && (
        <Route exact path={paths.LmsSessions.readyToScheduled.path} component={lazyLoad(ReadyToScheduledSessionPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.scheduled.accessLevels) && (
        <Route exact path={paths.LmsSessions.scheduled.path} component={lazyLoad(LmsScheduledSessionPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.ongoing.accessLevels) && (
        <Route exact path={paths.LmsSessions.ongoing.path} component={lazyLoad(LmsOngoingSessionPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.completed.accessLevels) && (
        <Route exact path={paths.LmsSessions.completed.path} component={lazyLoad(LmsCompletedSessionPages)} />
      )}
      {isAccessLevel(paths.LmsSessions.cancelled.accessLevels) && (
        <Route exact path={paths.LmsSessions.cancelled.path} component={lazyLoad(CancelledSessionsPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.abandoned.accessLevels) && (
        <Route exact path={paths.LmsSessions.abandoned.path} component={lazyLoad(LmsAbandonedSessionPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.elapsed.accessLevels) && (
        <Route exact path={paths.LmsSessions.elapsed.path} component={lazyLoad(LmsElapsedSessionPage)} />
      )}
      {isAccessLevel(paths.LmsSessions.elapsed.accessLevels) && <Route exact path={paths.LmsSessions.cmsSyncNeeded.path} component={lazyLoad(CmsSyncPage)} />}
      {isAccessLevel(paths.LmsSessions.archived.accessLevels) && <Route exact path={paths.LmsSessions.archived.path} component={lazyLoad(ArchivedPage)} />}
      {/* 1:1 sessions */}
      {/* all */}
      {isAccessLevel(paths.sessions1_1.all.accessLevel) && <Route exact path={paths.sessions1_1.all.path} component={lazyLoad(AllSessionsPage)} />}
      {/* Scheduled */}
      {isAccessLevel(paths.sessions1_1.scheduled.accessLevels) && <Route exact path={paths.sessions1_1.scheduled.path} component={lazyLoad(Scheduled)} />}
      {/* completed */}
      {isAccessLevel(paths.sessions1_1.completed.accessLevel) && (
        <Route exact path={paths.sessions1_1.completed.path} component={lazyLoad(CompletedSessionsPage)} />
      )}
      {/* ready to schedule */}
      {isAccessLevel(paths.sessions1_1.readyToSchedule.accessLevel) && (
        <Route exact path={paths.sessions1_1.readyToSchedule.path} component={lazyLoad(ReadyToSchedule)} />
      )}
      {/* Coach not assigned */}
      {isAccessLevel(paths.sessions1_1.coachNotAssigned.accessLevel) && (
        <Route exact path={paths.sessions1_1.coachNotAssigned.path} component={lazyLoad(CoachNotAssigned)} />
      )}
      {/* Elapsed */}
      {isAccessLevel(paths.sessions1_1.elapsed.accessLevel) && <Route exact path={paths.sessions1_1.elapsed.path} component={lazyLoad(Elapsed)} />}
      {/* Started or On going */}
      {isAccessLevel(paths.sessions1_1.started.accessLevel) && <Route exact path={paths.sessions1_1.started.path} component={lazyLoad(Ongoing)} />}
      {/* Abandoned */}
      {isAccessLevel(paths.sessions1_1.abandoned.accessLevel) && <Route exact path={paths.sessions1_1.abandoned.path} component={lazyLoad(Abandoned)} />}
      {/* Cancelled */}
      {isAccessLevel(paths.sessions1_1.cancelled.accessLevel) && <Route exact path={paths.sessions1_1.cancelled.path} component={lazyLoad(Cancelled)} />}
      {/* Pending Time slots  */}
      {isAccessLevel(paths.sessions1_1.pendingTimeSlots.accessLevel) && (
        <Route exact path={paths.sessions1_1.pendingTimeSlots.path} component={lazyLoad(PendingTimeSlots)} />
      )}
      {/* Reschedule Request */}
      {isAccessLevel(paths.sessions1_1.rescheduleRequest.accessLevel) && (
        <Route exact path={paths.sessions1_1.rescheduleRequest.path} component={lazyLoad(RescheduleRequest)} />
      )}
      {/* doubt session creation  */}
      {isAccessLevel(paths.sessions1_1.doubtSessions.accessLevel) && (
        <Route exact={true} path={paths.sessions1_1.doubtSessions.path} component={lazyLoad(DoubtSessionsCreation)} />
      )}
      {/* Details page */}
      {isAccessLevel(paths.sessions1_1.detailsPage.accessLevel) && <Route exact path={paths.sessions1_1.detailsPage.path} component={lazyLoad(DetailsPage)} />}
      {/* Join session page */}
      <Route exact path={paths.joinSession.path} component={lazyLoad(JoinSession)} />
      <Route exact path={paths.SessionDetails.path} component={lazyLoad(SessionDetails)} />
      {/* alpha board */}
      {isAccessLevel(paths.AlphaBoard.accessLevel) && <Route exact={true} path={paths.AlphaBoard.path} component={lazyLoad(AlphaBoard)} />}
      {/* Create Generic Session  */}
      {isAccessLevel(paths.CreateGenericSession.accessLevel) && (
        <Route exact path={paths.CreateGenericSession.path} component={lazyLoad(CreateGenericSession)} />
      )}
      {/* Generic session */}
      {isAccessLevel(paths.GenericSessions.all.accessLevels) && (
        <Route exact path={paths.GenericSessions.all.path} component={lazyLoad(AllGenericSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.readyToScheduled.accessLevels) && (
        <Route exact path={paths.GenericSessions.readyToScheduled.path} component={lazyLoad(ReadyToScheduledGenericSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.scheduled.accessLevels) && (
        <Route exact path={paths.GenericSessions.scheduled.path} component={lazyLoad(GenericScheduledSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.ongoing.accessLevels) && (
        <Route exact path={paths.GenericSessions.ongoing.path} component={lazyLoad(GenericOngoingSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.completed.accessLevels) && (
        <Route exact path={paths.GenericSessions.completed.path} component={lazyLoad(GenericCompletedSessionPages)} />
      )}
      {isAccessLevel(paths.GenericSessions.cancelled.accessLevels) && (
        <Route exact path={paths.GenericSessions.cancelled.path} component={lazyLoad(CancelledGenericSessionsPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.onHold.accessLevels) && (
        <Route exact path={paths.GenericSessions.onHold.path} component={lazyLoad(OnHoldGenericSessionsPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.abandoned.accessLevels) && (
        <Route exact path={paths.GenericSessions.abandoned.path} component={lazyLoad(GenericAbandonedSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.elapsed.accessLevels) && (
        <Route exact path={paths.GenericSessions.elapsed.path} component={lazyLoad(GenericElapsedSessionPage)} />
      )}
      {isAccessLevel(paths.GenericSessions.archived.accessLevels) && (
        <Route exact path={paths.GenericSessions.archived.path} component={lazyLoad(GenericArchivedPage)} />
      )}
      {/* Generic Details page */}
      {isAccessLevel(paths.GenericSessionDetails.accessLevels) && (
        <Route exact path={paths.GenericSessionDetails.path} component={lazyLoad(GenericSessionDetails)} />
      )}
      {/* Lead up */}
      {isAccessLevel(paths.LeadUp.accessLevel) && <Route exact path={paths.LeadUp.path} component={lazyLoad(LeadUp)} />}
      {/* Not Found */}
      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const { token } = state.user;
  return {
    token
  };
};

export default connect(mapStateToProps)(Routes);
