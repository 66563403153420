import { apiInstance } from "app/utils/api";

export const masterSessionCreation = async (values: any) => {
  const res = await apiInstance.post("/admin/masterSession/create", values);
  return res;
};

export const masterSessionGet = async () => {
  const res = await apiInstance.get("/admin/masterSession/getAll");
  return res;
};

export const masterSessionGetById = async (id: string) => {
  const res = await apiInstance.get(`/admin/masterSession/${id}`);
  return res;
};

export const masterSessionUpdation = async (values: any, id: string) => {
  const res = await apiInstance.put(`/admin/masterSession/${id}`, values);
  return res;
};

/**FOr the dummy data delete later */

export const getAllReferralsList = async () => {
  const res = await apiInstance.get("/admin/referral/list");
  return res.data;
};
/*** */
