import jwt from "jsonwebtoken";
import StorageService from "../../services/storage.service";
import CONSTANTS from "../constants";

export const getAccessLevel = () => {
  const token = StorageService.get("token");
  if (token) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    return decodedToken.payload.accessLevel;
  }
};

export const accessLevels = CONSTANTS.ACCESS_LEVEL;

export const isAccessLevel = accessLevel => {
  const tokenAccessLevel = getAccessLevel();
  if (accessLevels[tokenAccessLevel].levelValue < accessLevel.levelValue) {
    return false;
  }
  return true;
};
