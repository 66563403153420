import { Descriptions, Row, Col, Select, Form } from "antd";
import BISelectItem from "app/component/BISelectItem";
import BITextInput from "app/component/FormItems/BITextInput";
import { getFeedbackUrls } from "app/services/feedback.service";
import { getCoachFeedbackProviders, getLearnerFeedbackProviders } from "app/utils/common";
import { typeFormRules, urlRules } from "app/utils/validationRules";
import React, { FunctionComponent, useEffect, useState } from "react";

interface IProps {
  title: string;
  userType: string;
  feedbackData: any;
  setFeedbackData: any;
  tenantId: string;
  isRequired?: boolean;
  width?: string;
}

const { Option } = Select;

const FeedbackUrl: FunctionComponent<IProps> = ({ title, userType, feedbackData, setFeedbackData, tenantId, isRequired = false, width = "100%" }) => {
  const [biFormsData, setBiFormsData] = useState([]);

  const getBIFormsData = async () => {
    const res = await getFeedbackUrls(tenantId);
    setBiFormsData(res?.data?.data);
  };

  useEffect(() => {
    if (tenantId && feedbackData?.provider === "BIFORMS") {
      getBIFormsData();
    }
  }, [tenantId, feedbackData?.provider]);

  const handleFormChange = value => {
    setFeedbackData({ provider: "BIFORMS", url: value });
  };

  const handleProviderChange = () => {
    setFeedbackData({ ...feedbackData, url: undefined });
  };

  return (
    <>
      <Descriptions title={title} />
      <BISelectItem
        handleChange={handleProviderChange}
        isRequired={isRequired}
        width={width}
        placeholder="Select feedback provider"
        label="Select Provider"
        items={userType === "COACH" ? getCoachFeedbackProviders() : getLearnerFeedbackProviders()}
        initialValue={feedbackData?.provider}
        updateSelectChange={data => setFeedbackData({ ...feedbackData, provider: data })}
      />

      {feedbackData?.provider === "BIFORMS" ? (
        <Form.Item label="Select Form" required={isRequired}>
          <Select placeholder="Select form" style={{ width: width }} onChange={handleFormChange} defaultValue={feedbackData?.url}>
            {biFormsData?.map(data => data?.userType === userType && <Option value={data?.Id}>{`${data?.formName}   (${data?.Id})`}</Option>)}
          </Select>
        </Form.Item>
      ) : (
        <BITextInput
          required={isRequired}
          width={width}
          placeholder="Enter feedback url"
          label="Feedback Url"
          rules={feedbackData?.provider === "TYPEFORM" ? typeFormRules : urlRules}
          initialValue={feedbackData?.url}
          updateInputChange={data => setFeedbackData({ ...feedbackData, url: data?.value, hasError: data?.hasError })}
        />
      )}
    </>
  );
};

export default FeedbackUrl;
