export const createErrorMessageSelector = (actions: any) => (state: any) => {
  const errors: any = {};
  actions.forEach((action: any) => {
    errors[action] = state[action];
  });
  if (Object.keys(errors).length) {
    return errors;
  }
  return "";
};

export const createLoadingSelector = (actions: any) => (state: any) => actions.some((action: any) => state[action]);
