import React from "react";
import "./Login.scss";
import { withNaming } from "@bem-react/classname";
import { Card } from "antd";
import LoginForm from "./components/LoginForm";

const cn = withNaming({ e: "__", m: "--" })("Login");

const Login = () => {
  return (
    <div className={cn()}>
      <div className={cn("wrapper")}>
        <Card className={cn("card")}>
          <LoginForm />
        </Card>
      </div>
    </div>
  );
};

export default Login;
