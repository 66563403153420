import React from "react";
import { Result, Button } from "antd";
import MainLayout from "app/layouts/MainLayout";

const NotFound = () => {
  return (
    <MainLayout title="404" subtitle="Page Not Found">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Result
          status={404}
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button href="/" type="primary">
              Back Home
            </Button>
          }
        />
      </div>
    </MainLayout>
  );
};

export default NotFound;
