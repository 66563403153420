import axios, { AxiosInstance, AxiosResponse } from "axios";
import config from "../config";
import StorageService from "../services/storage.service";
import jwt from "jsonwebtoken";
import { timestampInSec } from "./time";

type responseHandlerType = (response: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>;

export const apiInstance: AxiosInstance = axios.create({
  baseURL: config.API_ENDPOINT,
  timeout: 300000, // 5 min
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

export const authApiInstance: AxiosInstance = axios.create({
  baseURL: config.AUTH_API_ENDPOINT,
  timeout: 300000, // 5 min
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

// LMS API instance
export const lmsApiInstance: AxiosInstance = axios.create({
  baseURL: config.LMS_API_ENDPOINT,
  timeout: 300000, // 5 min
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

export const lmsNodeApiInstance: AxiosInstance = axios.create({
  baseURL: config.API_LMS_NODE,
  timeout: 300000, // 5 min
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

const requestHandler = request => {
  const token = StorageService.get("token");
  if (token) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp < timestampInSec()) {
      return request;
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

apiInstance.interceptors.request.use(requestHandler);
authApiInstance.interceptors.request.use(requestHandler);
lmsNodeApiInstance.interceptors.request.use(requestHandler);
lmsApiInstance.interceptors.request.use(requestHandler);
const responseHandler: responseHandlerType = response => response;

const axiosErrorHandler: any = error => {
  if (error.response && error.response.status === 401) {
    // auth error
    localStorage.removeItem("bi_token");
    window.location.href = "/login";
  } else if (!error.response) {
    // Network Error.
    return Promise.reject({ message: "Network Error" });
  }
  return Promise.reject(error.response);
};

apiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => axiosErrorHandler(error)
);

authApiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => axiosErrorHandler(error)
);

lmsNodeApiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => axiosErrorHandler(error)
);

lmsApiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => axiosErrorHandler(error)
);

lmsNodeApiInstance.interceptors.response.use(
  response => responseHandler(response),
  error => axiosErrorHandler(error)
);

export const longApiInstanceV3: AxiosInstance = axios.create({
  baseURL: config.API_ENDPOINT_V3,
  timeout: 600000
});

longApiInstanceV3.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

longApiInstanceV3.interceptors.request.use(request => {
  const token = StorageService.get("token");
  if (token) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp < timestampInSec()) {
      return request;
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

export const longApiInstanceV2: AxiosInstance = axios.create({
  baseURL: config.API_ENDPOINT,
  timeout: 60000000
});

longApiInstanceV2.interceptors.request.use(request => {
  const token = StorageService.get("token");
  if (token) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp < timestampInSec()) {
      return request;
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

longApiInstanceV2.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

export const apiInstanceV3: AxiosInstance = axios.create({
  baseURL: config.API_ENDPOINT_V3,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

apiInstanceV3.interceptors.request.use(request => {
  const token = StorageService.get("token");
  if (token) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (decodedToken && decodedToken.payload && decodedToken.payload.exp < timestampInSec()) {
      return request;
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

apiInstanceV3.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);
apiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

authApiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

lmsApiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // auth error
      localStorage.removeItem("bi_token");
      window.location.href = "/login";
    } else if (!error.response) {
      // Network Error.
      return Promise.reject({ message: "Network Error" });
    }
    return Promise.reject(error.response);
  }
);

//end

const getRequest = (url: string, params: object = {}) => {
  return apiInstance
    .get(url, { params })
    .then(({ data }) => data.data)
    .catch(err => {
      throw err;
    });
};

const postRequest = (url: string, body: any) => {
  return apiInstance
    .post(url, body)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err;
    });
};

const deleteRequest = (url: string) => {
  return apiInstance
    .delete(`${url}`)
    .then(({ data }) => {
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const updateRequest = (url: string, body: any) => {
  return apiInstance
    .post(url, body)
    .then(({ data }) => data.data)
    .catch(err => {
      throw err;
    });
};

export default { getRequest, postRequest, deleteRequest, updateRequest };

export const getErrorAndCode = (err: any) => {
  let errorMessage = err.message;
  let errorCode = "000000";
  if (err && err.response && err.response.data && err.response.data.error) {
    errorMessage = err.response.data.error.message;
    errorCode = err.response.data.error.code;
  }
  return { message: errorMessage, code: errorCode };
};

// API Error Handling

export interface IAPIV2Error {
  message: string;
  statusCode: number;
}

export const defaultErrorMessage: IAPIV2Error = {
  message: "Something Went Wrong!",
  statusCode: 500
};

export const getError = (err: any) => {
  // err.response is thrown from response interceptor from api util
  if (err && err.data && err.data.error) {
    return err.data.error;
  }
  return defaultErrorMessage;
};
