import { IAdminInfo } from "app/interfaces/user.interface";
import { API } from "../utils";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_FULFILLED = "LOGIN_USER_FULFILLED";
export const LOGIN_USER_REJECTED = "LOGIN_USER_REJECTED";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_INFO = "SET_USER_INFO";

export const setAuthToken = (token: string) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: {
      token
    }
  };
};

export const setUserInfo = (userDetails: IAdminInfo) => {
  return {
    type: SET_USER_INFO,
    payload: { userDetails }
  };
};

export const login = (id: string, password: string) => {
  const requestBody = {
    id,
    password
  };
  return {
    type: LOGIN_USER,
    payload: API.postRequest("/admin/login", requestBody).then(data => {
      return {
        token: data.token
      };
    })
  };
};

export const logout = () => {
  return {
    type: LOGOUT_USER
  };
};
