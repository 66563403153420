import { API, to } from "../utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSES_PENDING = "FETCH_COURSES_PENDING";
export const FETCH_COURSES_FULFILLED = "FETCH_COURSES_FULFILLED";
export const FETCH_COURSES_REJECTED = "FETCH_COURSES_REJECTED";

export const FETCH_COURSES_REFRESH = "FETCH_COURSES_REFRESH";
export const FETCH_COURSES_REFRESH_PENDING = "FETCH_COURSES_REFRESH_PENDING";
export const FETCH_COURSES_REFRESH_FULFILLED = "FETCH_COURSES_REFRESH_FULFILLED";
export const FETCH_COURSES_REFRESH_REJECTED = "FETCH_COURSES_REJECTED";

export const ADD_COURSE = "ADD_COURSE";
export const ADD_COURSE_PENDING = "ADD_COURSE_PENDING";
export const ADD_COURSE_FULFILLED = "ADD_COURSE_FULFILLED";
export const ADD_COURSE_REJECTED = "ADD_COURSE_REJECTED";

export const COURSE_CHANGE_VIEW = "COURSE_CHANGE_VIEW";

export const COURSE_ADD_MODULE = "COURSE_ADD_MODULE";

export const COURSE_REMOVE_MODULE = "COURSE_REMOVE_MODULE";

export const COURSE_EDIT_MODULE = "COURSE_EDIT_MODULE";

export const COURSE_CLEAR_MODULE = "COURSE_CLEAR_MODULE";

export const COURSE_SET_FORM = "COURSE_SET_FORM";

export const COURSE_CLEAR_FORM = "COURSE_CLEAR_FORM";

export const getCourses = (params: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return new Promise(async (resolve, reject) => {
    const data = await to(API.getRequest("/admin/lms/courses", params));
    if (data && data.error) {
      dispatch({
        type: FETCH_COURSES_REJECTED,
        payload: {
          data
        }
      });
      return reject(data.error);
    }
    dispatch({
      type: FETCH_COURSES_FULFILLED,
      payload: {
        data: data.rows,
        total: data.total
      }
    });
    resolve(data);
  });
};

export const addCourse = (body: any) => {
  return {
    type: ADD_COURSE,
    payload: API.postRequest("/admin/lms/course", body).then(data => {
      return {
        course: data
      };
    })
  };
};

export const changeView = (view: number) => {
  return {
    type: COURSE_CHANGE_VIEW,
    payload: {
      view
    }
  };
};

export const addModule = (selectedModule: any) => {
  return {
    type: COURSE_ADD_MODULE,
    payload: {
      selectedModule
    }
  };
};

export const clearModule = () => {
  return {
    type: COURSE_CLEAR_MODULE
  };
};

export const removeModule = (id: string) => {
  return {
    type: COURSE_REMOVE_MODULE,
    payload: {
      id
    }
  };
};

export const editModule = (index: number, dependencies: any) => {
  return {
    type: COURSE_EDIT_MODULE,
    payload: {
      index,
      dependencies
    }
  };
};

export const setForm = (data: any) => {
  return {
    type: COURSE_SET_FORM,
    payload: {
      data
    }
  };
};

export const clearForm = () => {
  return {
    type: COURSE_CLEAR_FORM
  };
};
