import React, { useState, FunctionComponent, useEffect } from "react";
import styles from "./Product.module.scss";
import { Table, Empty, Icon, Button, Typography, Modal, Tag } from "antd";
import { history } from "app/store";
import { masterProductGet } from "app/services/masterProduct.service";
import { isAccessLevel } from "app/utils/accessLevel/accessLevel";
import paths from "app/utils/paths";
const { Paragraph } = Typography;

const Product: FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [fetchData, setFetchData] = useState<any>();
  const [imageModal, setImageModal] = useState(false);
  const [link, setLink] = useState<any>();
  //   const getTitle = (_value: any | []) => {
  //     _value.map((value: any) => productData[value - 1].title);
  //  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      width: 150
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      width: 150
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 100
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      width: 100
    },
    {
      title: "SP",
      dataIndex: "sp",
      key: "sp",
      width: 100
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
      width: 300
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 150,
      render: (text: any) => (
        <Button
          type="primary"
          onClick={() => {
            setImageModal(true);
            setLink(text);
          }}>
          Show Image
        </Button>
      )
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      width: 150,
      render: (text: any) => (
        <Button
          type="primary"
          onClick={() => {
            setImageModal(true);
            setLink(text);
          }}>
          Show Icon
        </Button>
      )
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      width: 150
      // TODO: to add filters
      // filters: [
      //   { text: 'True', value: 'true' },
      //   { text: 'False', value: 'false' },
      // ],
      // onFilter: (value: any, record: any) => record.enabled.includes(value),
      // filterMultiple: false,
    },
    {
      title: "Display",
      dataIndex: "display",
      key: "display",
      width: 150
      // TODO: to add filters
      // filters: [
      //   { text: 'True', value: 'true' },
      //   { text: 'False', value: 'false' },
      // ],
      // onFilter: (value: any, record: any) => record.display.includes(value),
      // filterMultiple: false,
    },
    {
      title: "Archived",
      dataIndex: "archived",
      key: "Archived",
      width: 150
      // TODO: to add filters
      // filters: [
      //   { text: 'True', value: 'true' },
      //   { text: 'False', value: 'false' },
      // ],
      // onFilter: (value: any, record: any) => record.archived.includes(value),
      // filterMultiple: false,
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      width: 150
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 150
    },
    {
      title: "Recommended",
      dataIndex: "recommended",
      key: "recommended",
      width: 190
    },
    {
      title: "Master Session",
      dataIndex: "masterSessions",
      key: "masterSession",
      width: 180
    }
  ];

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const res = await masterProductGet();
      const fetchResult = res.data.data.rows;
      const products = [];
      for (let i = 0; i < fetchResult.length; i++) {
        products.push({
          key: i + 1,
          id: i + 1,
          title: fetchResult[i]?.title,
          slug: fetchResult[i]?.slug,
          tags: fetchResult[i]?.tags.map((value: any, i: any) => (
            <Tag key={i} style={{ marginBottom: 5 }}>
              {value}
            </Tag>
          )),
          currency: fetchResult[i]?.currency,
          mrp: fetchResult[i]?.mrp,
          sp: fetchResult[i]?.sp,
          subtitle: <Paragraph ellipsis={{ rows: 2, expandable: true }}>{fetchResult[i]?.subtitle}</Paragraph>,
          description: <Paragraph ellipsis={{ rows: 2, expandable: true }}>{fetchResult[i]?.description}</Paragraph>,
          image: fetchResult[i]?.image,
          icon: fetchResult[i]?.icon,
          type: fetchResult[i]?.type,
          rank: fetchResult[i]?.rank,
          validity: new Date(fetchResult[i]?.validity)?.toDateString(),
          recommended: fetchResult[i]?.recommended?.map(({ details }: any, _i: any) => (
            <Tag key={_i} style={{ marginBottom: 5 }}>
              {details?.title}
            </Tag>
          )),
          masterSessions: fetchResult[i]?.masterSessions?.map(({ details }: any, _i: any) => (
            <Tag key={_i} style={{ marginBottom: 5 }}>
              {details?.title}
            </Tag>
          )),
          display: fetchResult[i]?.display === true ? "TRUE" : "FALSE",
          enabled: fetchResult[i]?.enabled === true ? "TRUE" : "FALSE",
          archived: fetchResult[i]?.archived === true ? "TRUE" : "FALSE"
        });
        setFetchData(products);
      }
    } catch (_error) {
      setError(_error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const getTablePlaceholder = () => {
    if (loading) {
      return <Empty description={<span>Fetching......</span>} />;
    }
    if (error) {
      return <h1>Server Down....</h1>;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };
  return (
    <React.Fragment>
      {isAccessLevel(paths.productCreate.accessLevel) && (
        <div className={styles.head}>
          <Button onClick={() => history.push(paths.productCreate.path)} type="primary">
            <Icon type="plus" /> Create
          </Button>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          pagination={{ pageSize: 100, total: fetchData?.length }}
          dataSource={fetchData}
          loading={loading}
          footer={undefined}
          bordered={true}
          locale={{
            emptyText: getTablePlaceholder()
          }}
          scroll={{ y: 580, x: 1500 }}
          size={"middle"}
        />
      </div>
      <Modal title="Image Modal" visible={imageModal} footer={null} closable={true} onCancel={() => setImageModal(false)}>
        <div>{link !== undefined && link !== "" && <img src={link} alt="product" />}</div>
      </Modal>
    </React.Fragment>
  );
};

export default Product;
