import { apiInstance } from "app/utils/api";

export const masterProductCreation = async (values: any) => {
  const res = await apiInstance.post("/admin/masterProduct/create", values);
  return res;
};

export const masterProductGet = async () => {
  const res = await apiInstance.get("/admin/masterProduct/getAll");
  return res;
};
