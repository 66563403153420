export type TProductKeyType = keyof typeof CONSTANTS.PRODUCT_TYPES;
export type TProductValueType = typeof CONSTANTS.PRODUCT_TYPES[TProductKeyType];
export type TWebinarType = keyof typeof CONSTANTS.WEBINAR_TYPE;

const CONSTANTS = Object.freeze({
  EDUCATION_LEVELS: {
    10: "10",
    12: "12",
    UG: "UG",
    PG: "PG",
    DOCTORATE: "DOCTORATE",
    DIPLOMA: "DIPLOMA"
  },
  PRODUCT_TYPES: {
    PRODUCT: "PRODUCT",
    PACKAGE: "PACKAGE",
    LEARNING_PATH: "LEARNING_PATH",
    MICRO_LEARNING: "MICRO_LEARNING",
    COACH_COURSES: "COACH_COURSES",
    COACH_COURSE: "COACH_COURSE", //For Filter purpose
    DEMO: "DEMO"
  } as const,
  PRODUCT_TEXT_MAP: {
    PACKAGE: "Career Coaching Package",
    PRODUCT: "Career Coaching Product",
    LEARNING_PATH: "Learning Path",
    MICRO_LEARNING: "Micro Learning",
    COACH_COURSE: "Coach Courses",
    DEMO: "Demo Sessions"
  },
  PRODUCT_CARD_STATUS: {
    UPCOMING: "UPCOMING",
    ONGOING: "ONGOING",
    COMPLETED: "COMPLETED"
  },
  USER_TYPE: {
    STUDENT: "STUDENT",
    COACH: "COACH"
  },
  API_ERROR_DEFAULT: {
    MESSAGE: "Something went wrong!"
  },
  LEARNING_PATH_STATUS: {
    FREE: "FREE",
    PARTIAL: "PARTIAL",
    FULL: "FULL",
    CANCELLED: "CANCELLED"
  },
  USER_ONBOARDING_STATUS: {
    FALSE: "FALSE",
    INVITED: "INVITED",
    PASSWORD_ADDED: "PASSWORD_ADDED",
    EDUCATION: "EDUCATION",
    EMPLOYMENT: "EMPLOYMENT",
    INTERESTS: "INTERESTS",
    TRUE: "TRUE"
  },
  SESSION_STATUS: {
    PRODUCT_ADDED: "PRODUCT_ADDED",
    READY_FOR_SCHEDULE: "READY_FOR_SCHEDULE",
    COACH_ASSIGNED: "COACH_ASSIGNED",
    COACH_TIMESLOT: "COACH_TIMESLOT",
    SESSION_SCHEDULED: "SESSION_SCHEDULED",
    SESSION_STARTED: "SESSION_STARTED",
    SESSION_OVER: "SESSION_OVER",
    STUDENT_FEEDBACK: "STUDENT_FEEDBACK",
    COACH_FEEDBACK: "COACH_FEEDBACK",
    COMPLETE: "COMPLETE",
    STUDENT_RESCHEDULE_REQUESTED: "STUDENT_RESCHEDULE_REQUESTED",
    COACH_RESCHEDULE_REQUESTED: "COACH_RESCHEDULE_REQUESTED",
    SESSION_ABANDONED: "SESSION_ABANDONED",
    CANCELLED: "CANCELLED",
    ON_HOLD: "ON_HOLD"
  },
  LMS_SESSION_STATUS: {
    ALL: "",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    STARTED: "STARTED",
    ELAPSED: "ELAPSED",
    ABADONED: "ABANDONED",
    SCHEDULED: "SCHEDULED",
    CMS_SYNC_NEEDED: "CMS_SYNC_NEEDED",
    READY_TO_SCHEDULE: "READY_TO_SCHEDULE",
    ARCHIVED: "ARCHIVED",
    ONHOLD: "ON_HOLD"
  },

  ACCESS_LEVEL: {
    OWNER: {
      level: "OWNER",
      levelValue: 4
    },
    ADMIN: {
      level: "ADMIN",
      levelValue: 3
    },
    MANAGER: {
      level: "MANAGER",
      levelValue: 2
    },
    AUTHOR: {
      level: "AUTHOR",
      levelValue: 1
    },
    LEADUP: {
      level: "LEADUP",
      levelValue: 0
    }
  },
  SESSION_VIDEO_TYPES: {
    VIMEO: "VIMEO"
  },

  JOINED_TYPE: {
    ALL: "ALL",
    JOINED: "TRUE",
    NOT_JOINED: "FALSE"
  },

  FEEDBACK_STATUS: {
    ALL: "ALL",
    COMPLETED: "TRUE",
    PENDING: "FALSE"
  },
  PROVIDER: {
    IMPARTUS: "IMPARTUS",
    DYTE: "DYTE",
    DYTE_WEBINAR: "DYTE_WEBINAR",
    ZOOM: "ZOOM",
    WHEREBY: "WHEREBY",
    H100MS: "H100MS",
    ZOHO_MEET: "ZOHO_MEET",
    BBB: "BBB"
  },

  MANUAL_PROVIDER: {
    ZOOM: "ZOOM"
  },
  productType: {
    learningPath: "LEARNING_PATH",
    microLearning: "MICRO_LEARNING"
  },
  SUBTYPES: {
    PROJECT_ASSIGNMENT: ["SPHERE_CODING_PRACTICE", "BI-LINK", "BI-IPYNB", "BI-ZIP", "BI-PDF"],
    QUIZ: ["BI-TEST"],
    EXAM: ["IMOCHA", "BI-TEST"]
  },

  WEBINAR_TYPE: {
    SINGLE: "SINGLE",
    CHILD: "CHILD",
    PARENT: "PARENT"
  },

  scheduleTypeOptions: {
    AUTO: "Auto",
    MANUAL: "Manual"
  },
  ALPHA_BOARD: "https://charts.mongodb.com/charts-boardinfinity-norzx/public/dashboards/60f0598d-e629-4a80-8289-4835b00139a6",
  LEARNER_VENTURES_LTD_TENANT_ID: "605ad8b8687e9b000e48548b",
  BOARD_INFINITY_TENANT_ID: "606ed6954bbb886956db587b"
});

export default CONSTANTS;
