import * as actions from "../actions/user";
import StorageService from "../services/storage.service";
import { IUser } from "../interfaces/user.interface";

const initialState: IUser = {
  token: StorageService.get("token"),
  adminInfo: null
};

const User = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.SET_ACCESS_TOKEN:
    case actions.LOGIN_USER_FULFILLED:
      action.payload.token = action.payload.token.replace("Bearer ", "");
      StorageService.set("token", action.payload.token);
      return { ...state, token: action.payload.token };
    case actions.LOGOUT_USER:
      StorageService.delete("token");
      StorageService.deleteAssignedBatches();
      return { ...state, token: "" };
    case actions.SET_USER_INFO:
      return {
        ...state,
        adminInfo: action.payload.userDetails
      };
    default:
      return state;
  }
};

export default User;
