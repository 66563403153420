import libnumber from "google-libphonenumber";

const PhoneUtil = libnumber.PhoneNumberUtil.getInstance();

export default {
  isValid: (phone: any) => {
    try {
      const number = PhoneUtil.parseAndKeepRawInput(phone);

      if (number) {
        return PhoneUtil.isValidNumberForRegion(number, PhoneUtil.getRegionCodeForNumber(number));
      }
    } catch (err) {
      return false;
    }
  }
};
