import { apiInstance } from "app/utils/api";

export const getFeedbackUrls = async (tenantId: string) => {
  const res = await apiInstance.get("/integrations/lms/getBIForm", {
    params: {
      Tenant_Id: tenantId
    }
  });
  return res.data;
};
