import moment from "moment";

export const timestampInSec = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const toISOFormat = (date: any) => {
  return new Date(date).toISOString();
};

export const getISOFormatCurrentTime = () => {
  return moment().toISOString();
};
