export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const zoomUrlPattern = /^https:\/\/(.*)zoom.us(.*)$/;

export const zohoUrlPattern = /^https:\/\/(.*)meet.zoho(.*)$/;

export const googleMeetUrlPattern = /^https:\/\/meet\.google\.com\/[a-zA-Z0-9-]+$/;

export const typeFormPattern = /^https:\/\/[a-zA-Z0-9\-]+\.(?:typeform\.com|tf\.ai)(.*)$/;

export const webexPattern = /^https:\/\/(.*)webex.com(.*)$/;

export const calendarUrlPattern = /^https:\/\/(.*)calendar.google.com(.*)$/;

// Top Level Domain eg-> .com, .in,.edu
export const tldRegex = /^@\w+(\.\w+)*$/;

// Image URL pattern
export const imageUrlPattern = /(http(s?):)\/\/(.*).(?:jpg|gif|png|jpeg|webp)/g;
