/* eslint-disable no-useless-escape */
import React from "react";
import { Button, Icon, Input, message } from "antd";
import _ from "lodash";
import moment from "moment";
import CONSTANTS from "./constants";
import Papa from "papaparse";
import copy from "copy-to-clipboard";
import phoneUtils from "./phoneUtils";
import { emailPattern, tldRegex } from "./regex";
import config from "app/config";

export const booleanYesNo = (b: boolean) => {
  return b ? "Yes" : "No";
};

export const isUserVerified = (user: any, userType?: string) => {
  if (user && userType === "COACH") {
    return user.emailVerified;
  } else if (user && userType === "STUDENT") {
    return user?.user?.emailVerified;
  } else if (user) {
    return user.emailVerified;
  } else {
    return false;
  }
};

// Non alphanumeric
export const nonAlphanumberRegex = /[^0-9a-z]/;

// Phone Regex
export const phoneRegex = /^[6-9]\d{9}$/;

// Slug Regex
export const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

// String to slug
export const slugify = (str: string) => {
  return str
    ?.toString() // cast to string
    .trim() // remove whitespaces from both ends
    .toLowerCase() // to lowercase
    .replace(/\s+/g, "-") // replace spaces with -
    .replace(/[^\w\-]+/g, "") // remove all non-word chars
    .replace(/\-\-+/g, "-") // replace multiple - with one -
    .replace(/^-+/, "") // remove - from start
    .replace(/-+$/, ""); //  remove - from end
};

// normalizeString - tolowercase and remove all non-alphanumeric characters
export const normalizeString = (val: string) => {
  return String(val).toLowerCase().replace(new RegExp(nonAlphanumberRegex, "g"), "");
};

// Number Regex
export const numberRegex = /^[0-9]*$/;

export const productTypes = {
  LEARNING_PATH: "LEARNING PATH",
  MICRO_LEARNING: "MICRO LEARNING"
};

/**
 * Returns the difference between two objects
 */
export const difference = (object: any, base: any) => {
  function changes(_object: any, _base: any) {
    return _.transform(_object, (result: any, value: any, key: any) => {
      if (!_.isEqual(value, _base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, _base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

// Browser Reload
export const reload = (hard: boolean) => {
  window.location.reload();
};

// Get vimeo id from url
export const getVimeoIdFromUrl = (url: string) => {
  const regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  const match = url.match(regExp);

  return match[2];
};

// normalizeString - tolowercase and remove all non-alphanumeric characters except space
export const normalizeStringExceptSpace = (val: string) => {
  return String(val)
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, "");
};

export const normalizeStringWithNumberExceptSpace = (val: string) => {
  return String(val)
    .toLowerCase()
    .replace(/[^A-Za-z0-9]+/g, "");
};

// website regex
export const websiteRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

//website regex with mandatory http or https
export const websiteRegexHttp = /^((https?):\/\/)?(www.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|^((https?):\/\/)?(www.)?[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;

export const getHMFormatTime = (minutes: any) => {
  let h;
  let m;
  if (minutes >= 60) {
    h = Math.floor(minutes / 60);
    m = minutes % 60;
    return m > 0 ? `${h} ${h > 1 ? "Hours" : "Hour"} ${m} ${m > 1 ? "Minutes" : "Minute"} ` : `${h} ${h > 1 ? "Hours" : "Hour"} `;
  } else {
    return `${minutes} ${minutes > 1 ? "Minutes" : "Minute"}`;
  }
};

//This function will use for  search option in the colum  of antd table
let searchInput;
export const getColumnSearchProps = (dataIndex: string, placeholder?: string) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />

      <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon="search" size="small" style={{ width: 90, marginRight: 8 }}>
        Search
      </Button>

      <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
  onFilter: (value, record) => _.get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  }
});

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = clearFilters => {
  clearFilters();
};
//*st->start time, et->end time  bt-> buffer time, bte -> buffer time from session end time
export const eligibleToJoinSession = (st, et, bt: number = 15, bte: number = 30) => {
  const currentTime = new Date();
  const endTime: any = new Date(moment(et).add(bte, "minute").toISOString());
  const joinableTime = new Date(st) <= new Date(moment(currentTime).add(bt, "minute").toISOString()) && currentTime <= endTime;

  return joinableTime;
};

export const userTypeColor = (userType?: string) => {
  if (userType?.toLocaleLowerCase() === "coach") {
    return "blue";
  } else if (userType?.toLocaleLowerCase() === "student" || "learner") {
    return "green";
  } else {
    return "purple";
  }
};

export const refresh = (s = 1000) => {
  setTimeout(() => {
    reload(true);
  }, s);
};

export const hasErrors = (fieldsError: any) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

export const getProviderItems = (scheduleType: string) => {
  if (scheduleType === "MANUAL") {
    return { ZOOM: "ZOOM", ZOHO_MEET: "ZOHO MEET", GOOGLE_MEET: "GOOGLE MEET", WEBEX: "WEBEX", OTHERS: "OTHERS" };
  } else {
    return { DYTE: "DYTE", H100MS: "H100MS", ZOOM: "ZOOM", BBB: "BIG BLUE BUTTON", DYTE_WEBINAR: "DYTE WEBINAR"};
  }
};

export const getCoachFeedbackProviders = () => {
  return {
    // TYPEFORM: "TYPEFORM",
    BIFORMS: "BIFORMS"
    // CUSTOM: "CUSTOM"
  };
};

export const getLearnerFeedbackProviders = () => {
  return {
    BIFORMS: "BIFORMS"
  };
};

export const showLinkInput = (provider: string, scheduleType: string) => {
  return scheduleType === "MANUAL";
};

export const downloadData = (data: any, filename: string, csvFormattedData = false) => {
  // csvFormattedData -> If data coming in api response is csv formatted.
  const csvData = csvFormattedData ? data : Papa.unparse(data);
  const blob = new Blob([csvData], {
    type: "text/csv;charset=utf-8"
  });
  const downloadUrl = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyValue = (value: any, msg = "Copied Successfully") => {
  copy(value);
  message.success(msg);
};
export const reloadPage = () => {
  setTimeout(() => {
    reload(true);
  }, 2000);
};

export const capitalizeName = (firstName: string, lastName: string) => {
  const name = `${firstName || ""} ${lastName || ""}`;
  const _names = name.split(" ");
  return _names?.map(n => _.capitalize(n)).join(" ");
};

export const validateMobile = (hasCallingCode = true, mobile, callingCode?: string | number) => {
  if (hasCallingCode) {
    return callingCode && mobile && phoneUtils.isValid(`+${callingCode}${mobile}`);
  } else {
    return phoneUtils.isValid(`+${mobile}`);
  }
};

export const validateEmail = (email: string) => {
  return email?.length > 0 && emailPattern.test(email);
};

export const validateEmailPattern = (rule: any, emailPatterns: string[], callback) => {
  emailPatterns?.forEach(email => {
    if (!tldRegex?.test(email)) {
      callback("Invalid Email Pattern");
    }
  });
  callback();
};

export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

export const getBITenantId = () => {
  return config.ENVIRONMENT === "development" ? CONSTANTS.LEARNER_VENTURES_LTD_TENANT_ID : CONSTANTS.BOARD_INFINITY_TENANT_ID;
};
