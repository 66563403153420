import React, { Suspense } from "react";
import BILoader from "app/component/BILoader";

const lazyLoad = (Component: any) => {
  return (props: any) => (
    <Suspense
      fallback={
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <BILoader />
        </div>
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default lazyLoad;
