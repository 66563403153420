import * as actions from "../actions/modules";
import { IModules } from "app/interfaces/modules.interface";

const initialState: IModules = {
  schema: [],
  data: [],
  rows: 0,
  view: 0,
  selectedComponents: [],
  form: {},
  selected: {}
};

const modules = (state = initialState, action: any) => {
  let selectedComponents;
  switch (action.type) {
    case actions.FETCH_MODULES_SCHEMA_FULFILLED:
      return { ...state, schema: action.payload.schema };

    case actions.FETCH_MODULES_FULFILLED:
      const data = [...state.data, ...action.payload.data];
      return { ...state, data, rows: action.payload.total };

    case actions.MODULE_CHANGE_VIEW:
      return { ...state, view: action.payload.view };

    case actions.MODULE_ADD_COMPONENT:
      return {
        ...state,
        selectedComponents: [...state.selectedComponents, action.payload.selectedComponent]
      };
    case actions.MODULE_CLEAR_COMPONENT:
      return {
        ...state,
        selectedComponents: []
      };

    case actions.MODULE_REMOVE_COMPONENT:
      const index = parseInt(action.payload.id.split("-")[1], 10);
      selectedComponents = state.selectedComponents.filter((s, i) => i !== index);
      selectedComponents = selectedComponents.map(s => {
        if (s.dependencies && Array.isArray(s.dependencies)) {
          let dep = s.dependencies.filter((d: string) => d !== action.payload.id);
          dep = dep.map((d: string) => {
            const temp = d.split("-");
            if (index < parseInt(temp[1], 10)) {
              temp[1] = (parseInt(temp[1], 10) - 1).toString();
            }
            return temp.join("-");
          });
          return { ...s, dependencies: dep };
        }
        return s;
      });
      return {
        ...state,
        selectedComponents
      };

    case actions.MODULE_EDIT_COMPONENT:
      selectedComponents = state.selectedComponents.map((s, i) => {
        if (i === action.payload.index) {
          return {
            ...s,
            dependencies: action.payload.dependencies
          };
        }
        return s;
      });
      return {
        ...state,
        selectedComponents
      };

    case actions.MODULE_SET_FORM:
      const form = { ...state.form, ...action.payload.data };
      return { ...state, form };

    case actions.MODULE_CLEAR_FORM:
      return { ...state, form: {} };

    case actions.MODULE_SELECT_SCHEMA:
      return { ...state, selected: action.payload.selected };

    case actions.MODULE_CLEAR_SELECTED_SCHEMA:
      return { ...state, selected: {} };

    default:
      return state;
  }
};

export default modules;
