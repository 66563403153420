import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Modal, Form, Input, Button, Icon, Alert, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { changeAdminPassword } from "app/services/admin.service";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { setAuthToken } from "app/actions/user";
import { getError } from "app/utils/api";

interface IProps extends FormComponentProps {
  visible: boolean;
  onCloseModal: any;
  title?: string;
  setPassLoading: any;
  passLoading: boolean;
  dispatch?: ThunkDispatch<any, any, AnyAction>;
}

const passwordMsg = [
  "must contain at least 1 lowercase alphabetical character",
  "must contain at least 1 uppercase alphabetical character",
  "must contain at least 1 numeric character",
  "must contain at least one special character",
  "must contain at least 6 characters"
];
const ChangePassword: React.FC<IProps> = ({ form, visible, onCloseModal, title = "Change Password", dispatch, passLoading, setPassLoading }) => {
  const [confirmDirty, setConfirmDirty] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { getFieldDecorator, getFieldsError, getFieldValue } = form;

  const hasErrors = (fieldsError: any) => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue("newPassword")) {
      callback("Password does not match!");
    } else {
      callback();
    }
  };

  const paswordFormat = () => {
    return (
      <Alert
        className={styles.passMsg}
        type="error"
        message={passwordMsg?.map(msg => {
          return (
            <p>
              <Icon type="caret-right" />
              {msg}
            </p>
          );
        })}
      />
    );
  };

  const validateToNextPassword = (rule, value, callback) => {
    const strongPassReg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,50}$/;
    if (!strongPassReg.test(value)) {
      callback(paswordFormat());
    }

    if (value && confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  const resetForm = () => {
    form.resetFields();
  };
  const handleCancel = () => {
    resetForm();
    onCloseModal();
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    form.validateFields(async (err, data) => {
      if (!err) {
        setPassLoading(true);
        try {
          const res = await changeAdminPassword(data);
          message.success("Password changed successfully");
          await dispatch(setAuthToken(res?.data?.token));
          setPassLoading(true);
        } catch (error) {
          message.error(getError(error).message);
          setPassLoading(false);
        }
      }
    });
  };
  useEffect(() => {
    if (visible === false) {
      resetForm();
    }
  }, [visible]);

  const checkBtnDisable = () => {
    if (getFieldValue("currentPassword") && getFieldValue("newPassword") && getFieldValue("confirmPassword") && !hasErrors(getFieldsError())) {
      return false;
    }
    return true;
  };
  const lockIcon = <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />;

  return (
    <Modal
      visible={visible}
      onCancel={onCloseModal}
      title={title}
      centered
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" loading={passLoading} disabled={checkBtnDisable()} type="primary" onClick={onSubmit}>
          {loading ? "Submitting..." : "Submit"}
        </Button>
      ]}>
      <Form>
        <Alert message="For security reasons, You will be logged out after password change/enter wrong current password" type="warning" />
        <Form.Item label="Current Password" hasFeedback>
          {getFieldDecorator("currentPassword", {
            rules: [
              {
                required: true,
                message: "Current Password is required"
              }
            ]
          })(<Input.Password placeholder="Current Password" prefix={lockIcon} minLength={8} />)}
        </Form.Item>
        <Form.Item label="New Password" hasFeedback>
          {getFieldDecorator("newPassword", {
            rules: [
              {
                required: true,
                message: "Please input your password!"
              },
              {
                validator: validateToNextPassword
              }
            ]
          })(<Input.Password placeholder="New Password" prefix={lockIcon} minLength={6} maxLength={50} />)}
        </Form.Item>
        <Form.Item label="Confirm Password" hasFeedback>
          {getFieldDecorator("confirmPassword", {
            rules: [
              {
                required: true,
                message: "Please confirm your password!"
              },
              {
                validator: compareToFirstPassword
              }
            ]
          })(<Input.Password placeholder="Confirm Password" prefix={lockIcon} onBlur={handleConfirmBlur} minLength={8} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    dispatch
  };
};

export default Form.create<IProps>({ name: "change_password" })(connect(null, mapDispatchToProps)(ChangePassword));
