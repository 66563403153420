import { numberRegex } from "./common";
import { zoomUrlPattern, zohoUrlPattern, calendarUrlPattern, googleMeetUrlPattern, webexPattern, typeFormPattern } from "./regex";

export const validationRules = {
  name: {
    nameType: (type: string = "name") => {
      return [
        {
          required: true,
          message: `Please enter ${type}.`
        }
      ];
    }
  },
  email: [
    {
      type: "email",
      message: "Please enter valid e-mail."
    },
    {
      required: true,
      message: "Please enter e-mail."
    }
  ],
  mobile: [
    {
      pattern: /^[6-9]\d{9}$/, // regex for indian mobile numbers
      message: "Please enter valid phone number."
    },
    {
      required: true,
      message: "Please enter phone number."
    }
  ],
  adminForm: {
    accessLevel: [
      {
        required: true,
        message: "Please select access level."
      }
    ],
    password: [
      {
        min: 8,
        message: "Password should atleast be 8 characters long."
      },
      {
        required: true,
        message: "Please enter password."
      }
    ]
  },
  coachForm: {
    basicDetails: {
      gender: [{ required: true, message: "Please select gender." }],
      nationality: [
        { required: true, message: "Please enter nationality." },
        {
          pattern: /^[a-zA-Z ]*$/,
          message: "Please enter text only."
        }
      ],
      secondaryEmail: [
        {
          type: "email",
          message: "Please enter valid e-mail."
        }
      ],
      secondaryMobile: [
        {
          message: "Please enter valid mobile number",
          pattern: /^[6-9]\d{9}$/
        }
      ]
    },
    socialProfiles: {
      linkedinUrl: [
        {
          pattern: /^http[s]{0,1}:\/\/((www|in)[.]){0,1}linkedin[.]com\/in\/[a-zA-Z0-9-]{5,30}/,
          message: "Please enter valid url."
        }
      ],
      url: [
        {
          pattern: /^(http:\/\/|https:\/\/)?(www.)?([a-zA-Z0-9]+).[a-zA-Z0-9]*.[a-z]{3}.?([a-z]+)?$/,
          message: "Please enter valid url."
        }
      ]
    }
  },
  platformId: [
    {
      required: true,
      message: "Please enter platform id."
    }
  ]
};

export const TESTMANAGEMENTVALIDATIONRULES = {
  TYPE: [{ required: true, message: "Please select test type." }],
  NAME: [{ required: true, message: "Please enter test name." }],
  NUMBEROFQUESTIONS: [
    { required: true, message: "Please enter number of questions." },
    {
      pattern: numberRegex,
      message: "Please enter valid number."
    }
  ],
  SKILLLEVEL: [{ required: true, message: "Please select skill level." }],
  DURATION: [
    { required: true, message: "Please enter test duration." },
    {
      pattern: numberRegex,
      message: "Please enter valid number."
    }
  ],
  MARKS: [{ required: true, message: "Please enter marks." }],
  NUMBEROFTABSSWITCHING: [{ required: true, message: "Please enter number of tabs switching." }]
};

export const zoomRules = [
  {
    pattern: zoomUrlPattern,
    message: "Please enter valid zoom url"
  }
];

export const zohoRules = [
  {
    pattern: zohoUrlPattern,
    message: "Please enter valid zoho url"
  }
];

export const googleMeetRules = [
  {
    pattern: googleMeetUrlPattern,
    message: "Please enter valid google meet url."
  }
];

export const webexRules = [
  {
    pattern: webexPattern,
    message: "Please enter valid webex url."
  }
];

export const urlRules = [{ type: "url", message: "Not a valid url" }];

export const typeFormRules = [{ pattern: typeFormPattern, message: "Please enter a valid Typeform url" }];

export const calendarRules = [
  {
    pattern: calendarUrlPattern,
    message: "Please enter a valid meeting link"
  }
];

export const basicRules = {
  requiredField: field => ({
    required: true,
    message: `${field} is Required`
  })
};
