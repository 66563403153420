import { notification } from "antd";

const showNotification = (type: "success" | "info" | "warning" | "error", message: string, description?: string) => {
  notification[type]({
    message,
    description
  });
};

export default showNotification;
