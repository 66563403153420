import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import * as userActions from "../actions/user";

// Reducers
import user from "./users";
import loading from "./loading";
import error from "./error";
import components from "./components";
import modules from "./modules";
import courses from "./courses";

// Interfaces
import { IUser } from "app/interfaces/user.interface";
import { IComponents } from "app/interfaces/components.interface";
import { IModules } from "app/interfaces/modules.interface";
import { ICourses } from "app/interfaces/courses.interface";

// GlobalState
export interface GlobalState {
  loading: any;
  error: any;
  user: IUser;
  components: IComponents;
  modules: IModules;
  courses: ICourses;
  router: RouterState;
}
const appReducer = (history: History) =>
  combineReducers<GlobalState>({
    loading,
    error,
    user,
    components,
    modules,
    courses,
    router: connectRouter(history)
  });

const rootReducer = (history: History) => (state: any, action: any) => {
  if (action.type === userActions.LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
