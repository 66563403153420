import React from "react";

const BILoader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: "none" }}
      width="100"
      height="100"
      className="lds-infinity"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100">
      <path
        fill="none"
        stroke="#00bfff"
        strokeDasharray="192.4416961669922 64.14723205566406"
        strokeWidth="4"
        d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40C88.6 30 95 43.3 95 50s-6.4 20-19.3 20c-19.3 0-32.1-40-51.4-40z">
        <animate attributeName="stroke-dashoffset" begin="0s" calcMode="linear" dur="1" keyTimes="0;1" repeatCount="indefinite" values="0;256.58892822265625" />
      </path>
    </svg>
  );
};

export default BILoader;
