import React, { useState, useEffect } from "react";
import { Table, Empty } from "antd";
import { withRouter } from "react-router";
import { apiInstanceV3 } from "app/utils/api";
import ErrorPlaceholder from "app/component/ErrorPlaceholder";

interface ICredit {
  available: number;
  used: number;
}

interface IData {
  provider: string;
  reportType: string;
  credit: ICredit;
}

const MessagingQuota: React.FunctionComponent = () => {
  const [data, setData] = useState<[IData]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getUserListData();
  }, []);

  const columns: any = [
    {
      title: "Report Type",
      dataIndex: "reportType",
      key: "reportType",
      width: 50
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      width: 50
    },
    {
      title: "Total Available Credit",
      dataIndex: "credit.available",
      key: "credit.available",
      width: 50
    },
    {
      title: "Used Credit",
      dataIndex: "credit.used",
      key: "credit.used",
      width: 50
    },
    {
      title: "Remaining Credit",
      key: "credit.used",
      width: 50,
      render: (text, record) => <span>{record?.credit?.available - record?.credit?.used}</span>
    }
  ];

  const getUserListData = async () => {
    setLoading(true);
    try {
      const res = await apiInstanceV3.get("tools/notification-service/usage-report", {});
      setError(null);
      setData(res.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const getTablePlaceholder = () => {
    if (loading) {
      return <Empty description="Loading..." />;
    }

    if (error) {
      return <ErrorPlaceholder message="Something went wrong!" />;
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <React.Fragment>
      <div>
        <Table
          columns={columns}
          dataSource={loading ? [] : data}
          rowKey="reportType"
          loading={loading}
          bordered={true}
          locale={{
            emptyText: getTablePlaceholder()
          }}
          size={"middle"}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(MessagingQuota);
