import React, { FunctionComponent, useState, useEffect } from "react";
import { Form, Input, Button, Icon, Select, notification, DatePicker, Tooltip, Alert, Divider } from "antd";
import { FormComponentProps } from "antd/lib/form";
import styles from "./ProductForm.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { apiInstance } from "app/utils/api";
import { getEnums, getTag } from "app/services/masterlist.service";
import moment from "moment";
import { masterProductCreation, masterProductGet } from "app/services/masterProduct.service";
import SelectTenant from "app/container/LmsSession/CreateLmsSession/components/SelectTenant/SelectTenant";
import { reload } from "app/utils/common";
import FeedbackUrl from "app/container/LmsSession/CreateLmsSession/components/FeedbackUrl";
import { IFeedBack } from "app/container/LmsSession/CreateLmsSession/helper";
const { Option } = Select;

interface IProp extends FormComponentProps {}
let id = 0;
const ProductForm: FunctionComponent<IProp> = ({ form }) => {
  const numberPattern = /^(0|[1-9][0-9]*)$/;
  const { getFieldDecorator, getFieldValue } = form;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [sessionValue, setSessionValue] = useState<any>();
  const [currencyEnum, setCurrencyEnum] = useState<any>();
  const [tagEnum, setTagEnum] = useState<any>();
  const [typeEnum, setTypeEnum] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [tenantId, setTenantId] = useState<string>();

  const [coachFeedbackData, setCoachFeedbackData] = useState<IFeedBack>();
  const [learnerFeedbackData, setLearnerFeedbackData] = useState<IFeedBack>();

  /* TODO: to add this once there is a API support */

  // const [logo, setLogo] = useState<any>();
  // const [iamge, setImage] = useState<any>();

  const getEnumsData = async () => {
    try {
      const res = await getEnums();
      if (res && res.masterProduct.currency) {
        // for converting object into array
        setCurrencyEnum(Object.values(res.masterProduct.currency));
      }
      if (res && res.masterProduct.type) {
        // for converting object into array
        setTypeEnum(Object.values(res.masterProduct.type));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTagEnum = async () => {
    try {
      const res = await getTag();
      if (res && res.data) {
        // for converting object into array
        setTagEnum(Object.values(res.data.rows));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const res = await masterProductGet();
      setProductList(res.data.data.rows);
    } catch (_error) {
      console.log(_error);
    } finally {
      setLoading(false);
    }
  };

  const getFeedbackUrl = feedbackData => {
    if (feedbackData?.provider === "BIFORMS") {
      return `https://forms.boardinfinity.com/?formid=${feedbackData?.url}&`;
    }
    return feedbackData?.url || "";
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    form.validateFieldsAndScroll(async (err: any, values: any) => {
      if (!err) {
        try {
          const formValues = values.names.filter((val: any) => {
            return val !== undefined;
          });
          const feedback = {
            urls: [
              {
                provider: coachFeedbackData.provider || "BIFORMS",
                url: getFeedbackUrl(coachFeedbackData),
                userType: "COACH",
                isRequired: true,
                isMultiple: false
              },
              {
                provider: learnerFeedbackData.provider || "BIFORMS",
                url: getFeedbackUrl(learnerFeedbackData),
                userType: "LEARNER",
                isRequired: true,
                isMultiple: false
              }
            ]
          };
          const productFormValues = { ...values, masterSessions: formValues, tenantId, feedback };

          const res = await masterProductCreation(productFormValues);
          if (res) {
            notification["success"]({
              message: "Product Added Successfully"
            });
            form.resetFields();
            reload(true);
          }
        } catch (_error) {
          notification["error"]({
            message: _error.data ? _error.data.error.message : _error.message
          });
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const fetchSession = async () => {
    try {
      const res = await apiInstance.get("/admin/masterSession/getAll");
      setSessionValue(res.data.data.rows);
    } catch (_error) {
      console.log(_error);
    }
  };
  useEffect(() => {
    getEnumsData();
    fetchSession();
    fetchProduct();
    getTagEnum();
  }, []);

  // To add fields in master list
  const add = () => {
    // can use data-binding to get
    const _keys = form.getFieldValue("masterSessions");
    const nextKeys = _keys.concat((id = id + 1));
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      masterSessions: nextKeys
    });
  };

  // To remove fields in master list
  const remove = (k: any) => {
    // can use data-binding to get
    const _keys = form.getFieldValue("masterSessions");
    // We need at least one passenger
    if (_keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      masterSessions: masterSession.filter((key: any) => key !== k)
    });
  };

  getFieldDecorator("masterSessions", {
    validateTrigger: ["onChange", "onBlur"],
    rules: [],
    initialValue: [0]
  });
  const masterSession = getFieldValue("masterSessions");

  const formItems = masterSession.map((k: any, index: number) => (
    <Form.Item required={false} key={k}>
      {getFieldDecorator(`names[${k}]`, {
        validateTrigger: ["onChange", "onBlur"],
        rules: [
          {
            required: true,
            whitespace: true,
            message: "Please select master sessions "
          }
        ]
      })(
        <Select
          placeholder="Please select"
          showSearch
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {sessionValue &&
            sessionValue.map((value: any, i: number) => (
              <Option value={value._id} key={i}>
                {value.title}
              </Option>
            ))}
        </Select>
      )}
      {masterSession.length > 1 ? <Icon className="dynamic-delete-button" type="minus-circle-o" onClick={() => remove(k)} /> : null}
    </Form.Item>
  ));

  return (
    <div>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <Alert className={styles.alert} type="warning" showIcon message="If no Tenant is selected, it will be a Generic product." />
        <SelectTenant width="100%" placeholder="Select tenant" label="Select Tenant" updateSelectChange={value => setTenantId(value)} />
        <Form.Item label="Title">
          {getFieldDecorator("title", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid title."
              },
              {
                required: true,
                message: "Please enter an title."
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Slug">
          {getFieldDecorator("slug", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid Slug."
              },
              {
                required: true,
                message: "Please enter an slug."
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Currency">
          {getFieldDecorator("currency", { initialValue: "INR" })(
            <Select>
              {currencyEnum &&
                currencyEnum.map(({ value }: any, i: number) => (
                  <Option value={value} key={i}>
                    {value}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="MRP">
          {getFieldDecorator("mrp", {
            rules: [
              {
                required: true,
                message: "Please enter product mrp."
              },
              {
                pattern: numberPattern,
                message: "Please enter only numeric values"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Selling Price">
          {getFieldDecorator("sp", {
            rules: [
              {
                required: true,
                message: "Please enter product selling price."
              },
              {
                pattern: numberPattern,
                message: "Please enter only numeric values"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Subtitle">
          {getFieldDecorator("subtitle", {
            rules: [
              {
                type: "string",
                message: "Please enter a valide subtitle."
              },
              {
                required: true,
                message: "Please enter product subtitle."
              }
            ]
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid description."
              },
              {
                required: true,
                message: "Please enter product description."
              }
            ]
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="Image">
          {getFieldDecorator("image", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid image link."
              },
              {
                required: true,
                message: "Please enter an image link."
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Icon">
          {getFieldDecorator("icon", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid icon link."
              },
              {
                required: true,
                message: "Please enter an icon link."
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Type">
          {getFieldDecorator("type", {
            rules: [
              {
                type: "string",
                message: "Please enter a valid type."
              },
              {
                required: true,
                message: "Please enter product type."
              }
            ]
          })(
            <Select>
              {typeEnum &&
                typeEnum.map(({ value }: any, i: number) => (
                  <Option value={value} key={i}>
                    {value}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Tag">
          {getFieldDecorator("tags", {
            rules: [
              {
                required: true,
                message: "Please select atleast one tag"
              }
            ]
          })(
            <Select mode="multiple" placeholder="Please select">
              {tagEnum &&
                tagEnum.map(({ name }: any, i: number) => (
                  <Option value={name} key={i}>
                    {name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Recommended">
          {getFieldDecorator("recommended", {
            rules: [
              {
                required: true,
                message: "Please select atleast one recommended product"
              }
            ]
          })(
            <Select mode="multiple">
              {productList &&
                productList.map((value: any, i: number) => (
                  <Option value={value._id} key={i}>
                    {value.title}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="CRM_ID">{getFieldDecorator("crmId")(<Input placeholder="Enter CRM-ID" />)}</Form.Item>

        <Form.Item label="Rank">
          {getFieldDecorator("rank", {
            rules: [
              {
                pattern: numberPattern,
                message: "Please enter an rank in number."
              }
            ],
            initialValue: 0
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Validity">
          {getFieldDecorator("validity", {
            initialValue: moment("2050-01-01T12:00:00")
          })(<DatePicker />)}
        </Form.Item>
        {/* <Form.Item label='Master Session'>
          {getFieldDecorator(
            'masterSessions',
            {
              rules:[
                {
                required: true,
                message: 'Please select an master session'
                }]
            },
          )(
            <Select mode='multiple' placeholder='Please select'>
              {sessionValue &&
                sessionValue.map((value: any, index: number) => (
                  <Option value={value._id} key={index}>
                    {value.title}
                  </Option>
                ))}
            </Select>,
          )}
        </Form.Item> */}
        <div className={styles.masterSession}> {formItems}</div>
        <Form.Item label="Master Session">
          <Button type="dashed" onClick={() => add()}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item>

        <Divider>Feedback Urls</Divider>
        <FeedbackUrl title="Coach" userType="COACH" feedbackData={coachFeedbackData} setFeedbackData={setCoachFeedbackData} tenantId={tenantId} />
        <FeedbackUrl title="Learner" userType="LEARNER" feedbackData={learnerFeedbackData} setFeedbackData={setLearnerFeedbackData} tenantId={tenantId} />

        <div className={styles.buttonWrapper}>
          <Tooltip title={!tenantId && "If no Tenant is selected, it will be a Generic product."}>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Tooltip>
        </div>
      </Form>
    </div>
  );
};

const ProductCreationForm = Form.create<IProp>({ name: "form" })(ProductForm);
export default ProductCreationForm;
