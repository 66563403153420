import React, { useEffect, useState } from "react";
import { Select, Form, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { apiInstanceV3 } from "app/utils/api";
const { Option } = Select;

interface IProps extends FormComponentProps {
  width?: string | number;
  placeholder?: string;
  updateSelectChange?: any;
  label?: string;
  layout?: string;
  isRequired?: boolean;
  layoutSpan?: any;
  layoutValues?: any;
  disable?: boolean;
  initialValue?: string;
}

const SelectTenant: React.FunctionComponent<IProps> = ({
  width = 200,
  placeholder,
  updateSelectChange,
  label,
  layout = "vertical",
  isRequired = false,
  form,
  layoutSpan,
  layoutValues,
  disable = false,
  initialValue = null
}) => {
  const [companyList, setCompanyList] = useState<any>([]);
  const { getFieldValue, getFieldDecorator } = form;

  const fetchTags = async () => {
    try {
      const res = await apiInstanceV3.get("/products/companies");
      setCompanyList(res.data.data);
    } catch (error) {
      message.error("Tag request failed!");
    }
  };

  useEffect(() => {
    fetchTags();
    if (initialValue) {
      form.setFieldsValue({ selectedItem: initialValue });
    }
  }, [initialValue]);

  useEffect(() => {
    const selectedItem = getFieldValue("selectedItem");
    updateSelectChange(selectedItem);
  }, [getFieldValue("selectedItem")]);

  //jsx and layout
  const formLayout =
    layout === "horizontal"
      ? {
          labelCol: { span: layoutValues.labelColSpan },
          wrapperCol: { span: layoutValues.wrapperColSpan, offset: layoutValues.wrapperColOffset }
        }
      : null;

  return (
    <Form.Item label={label} {...formLayout}>
      {getFieldDecorator("selectedItem", {
        rules: [
          {
            required: isRequired,
            message: "This field is Required"
          }
        ]
      })(
        <Select disabled={disable} style={{ width: width }} placeholder={placeholder} optionFilterProp="children" showSearch>
          {companyList?.map(company => (
            <Option value={company?._id}>{company?.companyName}</Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default Form.create<IProps>({ name: "selectTenant" })(SelectTenant);
