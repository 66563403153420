import React, { FunctionComponent } from "react";
import MainLayout from "app/layouts/MainLayout";
import Session from "app/container/Session";

const AdminPage: FunctionComponent = () => {
  return (
    <MainLayout title="Sessions" subtitle="The sessions that we offer">
      <Session />
    </MainLayout>
  );
};

export default AdminPage;
