import { apiInstance, apiInstanceV3 } from "app/utils/api";

export const getAdminInfo = async () => {
  const res = await apiInstance.get("/admin/me");
  return res.data;
};

export const deactivateAdmin = async (userId: string, remarks: string) => {
  const res = await apiInstanceV3.delete("/admins/deactivate", {
    data: { userId, remarks }
  });

  return res.data;
};

export const changeAdminPassword = async data => {
  const res = await apiInstanceV3.put("/admin-users/change-password", data);

  return res.data;
};
