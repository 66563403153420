import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { hasErrors } from "app/utils/common";
import { ILayoutValues } from "../helper";

interface IProps extends FormComponentProps {
  label?: string;
  placeholder?: string;
  width?: string | number;
  initialValue?: string;
  layout?: "horizontal" | "vertical";
  required?: boolean;
  layoutSpan?: number;
  updateInputChange: any;
  reset?: boolean;
  hint?: string;
  rules?: any[];
  setFormObj?: any;
  layoutValues?: ILayoutValues;
}

const BITextInput: React.FunctionComponent<IProps> = ({
  form,
  label,
  placeholder,
  width,
  initialValue = null,
  layout = "vertical",
  required = false,
  layoutSpan,
  updateInputChange,
  hint,
  reset,
  rules = [],
  setFormObj,
  layoutValues
}) => {
  const { getFieldDecorator, getFieldValue } = form;

  useEffect(() => {
    if (reset) {
      form.setFieldsValue({ value: undefined });
    }
  }, [reset]);

  useEffect(() => {
    const textInp = getFieldValue("value");
    const hasError = hasErrors(form.getFieldsError());
    updateInputChange({ value: textInp, hasError });
    if (setFormObj) {
      setFormObj(form);
    }
  }, [getFieldValue("value")]);

  const formLayout =
    layout === "horizontal"
      ? {
          labelCol: { span: layoutValues.labelColSpan },
          wrapperCol: { span: layoutValues.wrapperColSpan, offset: layoutValues.wrapperColOffset }
        }
      : null;
  return (
    <Form.Item extra={hint} label={label} {...formLayout}>
      {getFieldDecorator("value", {
        initialValue: initialValue,
        rules: [
          {
            required: required,
            message: `${label || "This field"} is required`
          },
          ...rules
        ]
      })(<Input allowClear placeholder={placeholder} style={{ width: width }} />)}
    </Form.Item>
  );
};

export default Form.create<IProps>({ name: "text-input" })(BITextInput);
