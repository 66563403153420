import * as actions from "../actions/components";
import { IComponents } from "app/interfaces/components.interface";

const initialState: IComponents = {
  schema: [],
  data: [],
  rows: 0,
  form: {},
  selected: {}
};

const components = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.FETCH_COMPONENTS_SCHEMA_FULFILLED:
      return { ...state, schema: action.payload.schema };
    case actions.FETCH_COMPONENTS_FULFILLED:
      const data = [...state.data, ...action.payload.data];
      return { ...state, data, rows: action.payload.total };

    case actions.COMPONENT_SET_FORM:
      const form = { ...state.form, ...action.payload.data };
      return { ...state, form };

    case actions.COMPONENT_CLEAR_FORM:
      return { ...state, form: {} };

    case actions.COMPONENT_SELECT_SCHEMA:
      return { ...state, selected: action.payload.selected };

    case actions.COMPONENT_CLEAR_SELECTED_SCHEMA:
      return { ...state, selected: {} };
    default:
      return state;
  }
};

export default components;
