import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "./Session.module.scss";
import { Empty, Table, Button, Icon, Modal, Typography, Tag } from "antd";
import { history } from "app/store";
import { masterSessionGet } from "app/services/masterSession.service";
import { isAccessLevel } from "app/utils/accessLevel/accessLevel";
import paths from "app/utils/paths";
const { Paragraph } = Typography;

const Session: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [fetchData, setFetchData] = useState<any>();
  const [imageModal, setImageModal] = useState(false);
  const [link, setLink] = useState<any>();

  const fetchSession = async () => {
    setLoading(true);
    try {
      const res = await masterSessionGet();
      const fetchResult = res.data.data.rows;
      const sessions = [];
      for (let i = 0; i < fetchResult.length; i = i + 1) {
        sessions.push({
          key: i + 1,
          _id: fetchResult[i]?._id,
          title: fetchResult[i]?.title,
          slug: fetchResult[i]?.slug,
          tags: fetchResult[i]?.tags?.map((value: any, key: any) => (
            <Tag style={{ marginBottom: 5 }} key={key}>
              {value}
            </Tag>
          )),
          description: <Paragraph ellipsis={{ rows: 2, expandable: true }}>{fetchResult[i]?.description}</Paragraph>,
          coachGuideline: <Paragraph ellipsis={{ rows: 2, expandable: true }}>{fetchResult[i]?.coachGuideline}</Paragraph>,
          studentGuideline: <Paragraph ellipsis={{ rows: 2, expandable: true }}>{fetchResult[i]?.studentGuideline}</Paragraph>,
          image: fetchResult[i]?.image,
          icon: fetchResult[i]?.icon,
          durationInMin: fetchResult[i]?.durationInMin,
          settings: fetchResult[i]?.settings,
          requiredFields: fetchResult[i]?.requiredFields?.map((value: [], key: number) => (
            <Tag style={{ marginBottom: 5 }} key={key}>
              {value}
            </Tag>
          ))
        });
        setFetchData(sessions);
      }
    } catch (_error) {
      setError(_error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSession();
  }, []);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "_id",
      width: 80,
      fixed: true,
      render: (value, record, index) => index + 1
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      width: 180
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 150
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500
    },
    {
      title: "Duration (in Min)",
      dataIndex: "durationInMin",
      key: "durationInMin",
      width: 130
    },
    {
      title: "Coach Guideline",
      dataIndex: "coachGuideline",
      key: "coachGuideline",
      width: 500
    },
    {
      title: "Student Guideline",
      dataIndex: "studentGuideline",
      key: "studentGuideline",
      width: 500
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 150,
      render: (text: any) => (
        <Button
          type="primary"
          onClick={() => {
            setImageModal(true);
            setLink(text);
          }}>
          Show Image
        </Button>
      )
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      width: 150,
      render: (text: any) => (
        <Button
          type="primary"
          onClick={() => {
            setImageModal(true);
            setLink(text);
          }}>
          Show Icon
        </Button>
      )
    },
    {
      title: "Required Fields",
      dataIndex: "requiredFields",
      key: "requiredFields",
      width: 150
    },
    {
      title: "Enable AI Feedback",
      dataIndex: "settings.enableAIFeedback",
      key: "feedback",
      width: 150,
      render: (text: any) => {
        return <Tag color={text ? "green" : "red"}>{text ? "Yes" : "No"}</Tag>;
      }
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text: any, record: any) => {
        return (
          <Button type="primary" onClick={() => window.open(`/session/edit/${record?._id}`, "_blank")}>
            <Icon type="edit" />
            Edit
          </Button>
        );
      }
    }
  ];

  const getTablePlaceholder = () => {
    if (loading) {
      return <Empty description={<span>Fetching......</span>}></Empty>;
    }
    if (error) {
      return <h1>Server Down....</h1>;
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <React.Fragment>
      {isAccessLevel(paths.sessionCreate.accessLevel) && (
        <div className={styles.head}>
          <Button onClick={() => history.push("/sessions/create")} type="primary">
            <Icon type="plus" /> Create
          </Button>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          pagination={false}
          dataSource={fetchData}
          loading={loading}
          footer={undefined}
          bordered={true}
          locale={{
            emptyText: getTablePlaceholder()
          }}
          scroll={{ y: 580, x: 1500 }}
          size={"middle"}
        />
      </div>
      <Modal title="Image Modal" visible={imageModal} footer={null} closable={true} onCancel={() => setImageModal(false)}>
        <div>{link !== undefined && link !== "" && <img src={link} alt="session" />}</div>
      </Modal>
    </React.Fragment>
  );
};

export default Session;
